/*           Font Smoothing      */
#legacy-root h1,
#legacy-root .h1,
#legacy-root h2,
#legacy-root .h2,
#legacy-root h3,
#legacy-root .h3,
#legacy-root h4,
#legacy-root .h4,
#legacy-root h5,
#legacy-root .h5,
#legacy-root h6,
#legacy-root .h6,
#legacy-root p,
#legacy-root .navbar,
#legacy-root .brand,
#legacy-root a,
#legacy-root .td-name,
#legacy-root td,
#legacy-root button,
#legacy-root input,
#legacy-root select,
#legacy-root textarea {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-family: $font-family-sans-serif;
	font-weight: $font-weight-light;
}

#legacy-root h1,
#legacy-root .h1,
#legacy-root h2,
#legacy-root .h2,
#legacy-root h3,
#legacy-root .h3,
#legacy-root h4,
#legacy-root .h4 {
	margin: $margin-large-vertical 0 0;
}
#legacy-root h1,
#legacy-root .h1 {
	font-size: $font-size-h1;
}
#legacy-root h2,
#legacy-root .h2 {
	font-size: $font-size-h2;
}
#legacy-root h3,
#legacy-root .h3 {
	font-size: $font-size-h3;
	line-height: 1.4;
	margin: 20px 0 0px;
}
#legacy-root h4,
.h4 {
	font-size: $font-size-h4;
	line-height: 1.2em;
}
#legacy-root h5,
#legacy-root .h5 {
	font-size: $font-size-h5;
	line-height: 1.4em;
}
#legacy-root h6,
#legacy-root .h6 {
	font-size: $font-size-h6;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	line-height: 1.5em;
}
#legacy-root p {
	font-size: $font-paragraph;
	line-height: $line-height-general;
	margin-bottom: 5px;
}
#legacy-root h1 small,
#legacy-root h2 small,
#legacy-root h3 small,
#legacy-root h4 small,
#legacy-root h5 small,
#legacy-root h6 small,
#legacy-root .h1 small,
#legacy-root .h2 small,
#legacy-root .h3 small,
#legacy-root .h4 small,
#legacy-root .h5 small,
#legacy-root .h6 small,
#legacy-root h1 .small,
#legacy-root h2 .small,
#legacy-root h3 .small,
#legacy-root h4 .small,
#legacy-root h5 .small,
#legacy-root h6 .small,
#legacy-root .h1 .small,
#legacy-root .h2 .small,
#legacy-root .h3 .small,
#legacy-root .h4 .small,
#legacy-root .h5 .small,
#legacy-root .h6 .small {
	color: $dark-gray;
	line-height: $line-height-general;
}

#legacy-root h1 small,
#legacy-root h2 small,
#legacy-root h3 small,
#legacy-root h1 .small,
#legacy-root h2 .small,
#legacy-root h3 .small {
	font-size: 60%;
}

#legacy-root .title,
#legacy-root .card-title,
#legacy-root .info-title,
#legacy-root .footer-brand,
#legacy-root .footer-big h5,
#legacy-root .footer-big h4,
#legacy-root .media .media-heading {
	font-family: $font-family-sans-serif;

	&,
	a {
		color: $card-black-color;
		text-decoration: none;
	}
}
#legacy-root .title-uppercase {
	text-transform: uppercase;
}
#legacy-root .description {
	color: $dark-gray;
}
#legacy-root blockquote small {
	font-style: normal;
}
#legacy-root .text-muted {
	color: $medium-gray;
}
#legacy-root .text-primary,
#legacy-root .text-primary:hover {
	color: $primary-color !important;
}
#legacy-root .text-info,
#legacy-root .text-info:hover {
	color: $info-color !important;
}
#legacy-root .text-success,
#legacy-root .text-success:hover {
	color: $success-color !important;
}
#legacy-root .text-warning,
#legacy-root .text-warning:hover {
	color: $warning-color !important;
}
#legacy-root .text-danger,
#legacy-root .text-danger:hover {
	color: $danger-color !important;
}
#legacy-root .glyphicon {
	line-height: 1;
}

// Pulsing Heart (footer)
#legacy-root .heart {
	color: #eb5e28;
	animation: heathing 1s ease infinite;
}

@keyframes heathing {
	0% {
		transform: scale(0.75);
	}
	20% {
		transform: scale(1);
	}
	40% {
		transform: scale(0.75);
	}
	60% {
		transform: scale(1);
	}
	80% {
		transform: scale(0.75);
	}
	100% {
		transform: scale(0.75);
	}
}
#legacy-root .footer .credits,
#legacy-root .footer-nav {
	line-height: 85px;
}
#legacy-root .footer .btn {
	margin-bottom: 0;
}
#legacy-root .blockquote {
	border-left: 0 none;
	border-bottom: 1px solid #ccc5b9;
	border-top: 1px solid #ccc5b9;
	font-weight: 300;
	margin: 15px 0 10px;
	text-align: center;
}
#legacy-root .title {
	margin-top: 30px;
	margin-bottom: 25px;
	min-height: 32px;
}
#legacy-root .title.text-center {
	margin-bottom: 50px;
}
