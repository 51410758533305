.popover {
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
    z-index: 1050;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
    border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
    border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
    border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-tooltip-auto[x-placement^='top'] .arrow::after,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^='left'] .arrow::after,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^='right'] .arrow::after,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::after,
.bs-tooltip-bottom .arrow::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::after,
.bs-tooltip-left .arrow::after {
    right: 1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #fff;
}
.bs-tooltip-auto[x-placement^='top'] .arrow::after,
.bs-tooltip-top .arrow::after {
    bottom: 1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #fff;
}
.bs-tooltip-auto[x-placement^='right'] .arrow::after,
.bs-tooltip-right .arrow::after {
    left: 1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #fff;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow::after,
.bs-tooltip-bottom .arrow::after {
    top: 1px;
    border-width: 0 0.4rem 0.4rem 0.4rem;
    border-bottom-color: #fff;
}
