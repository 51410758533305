/*--------------------------------

CureMint Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
	font-family: 'CureMint';
	src: url('../fonts/CureMint.eot');
	src: url('../fonts/CureMint.eot') format('embedded-opentype'),
		url('../fonts/CureMint.woff2') format('woff2'), url('../fonts/CureMint.woff') format('woff'),
		url('../fonts/CureMint.ttf') format('truetype'), url('../fonts/CureMint.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.cm-icon {
	display: inline-block;
	font: normal normal normal 1em/1 'CureMint';
	speak: none;
	text-transform: none;
	/* Better Font Rendering */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.cm-icon-sm {
	font-size: 0.8em;
}
.cm-icon-lg {
	font-size: 1.2em;
}
/* absolute units */
.cm-icon-16 {
	font-size: 16px;
}
.cm-icon-32 {
	font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.cm-icon-bg-square,
.cm-icon-bg-circle {
	padding: 0.35em;
	background-color: #eee;
}
.cm-icon-bg-circle {
	border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.cm-icon-ul {
	padding-left: 0;
	list-style-type: none;
}
.cm-icon-ul > li {
	display: flex;
	align-items: flex-start;
	line-height: 1.4;
}
.cm-icon-ul > li > .cm-icon {
	margin-right: 0.4em;
	line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.cm-icon-is-spinning {
	-webkit-animation: cm-icon-spin 2s infinite linear;
	-moz-animation: cm-icon-spin 2s infinite linear;
	animation: cm-icon-spin 2s infinite linear;
}
@-webkit-keyframes cm-icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@-moz-keyframes cm-icon-spin {
	0% {
		-moz-transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(360deg);
	}
}
@keyframes cm-icon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.cm-icon-rotate-90 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.cm-icon-rotate-180 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	-webkit-transform: rotate(180deg);
	-moz-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
}
.cm-icon-rotate-270 {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	-webkit-transform: rotate(270deg);
	-moz-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	-o-transform: rotate(270deg);
	transform: rotate(270deg);
}
.cm-icon-flip-y {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
	-webkit-transform: scale(-1, 1);
	-moz-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	transform: scale(-1, 1);
}
.cm-icon-flip-x {
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
	-webkit-transform: scale(1, -1);
	-moz-transform: scale(1, -1);
	-ms-transform: scale(1, -1);
	-o-transform: scale(1, -1);
	transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.cm-icon-tooth-o::before {
	content: '\ea02';
}

.cm-icon-tooth-sm::before {
	content: '\ea03';
}

.cm-icon-hospital-33::before {
	content: '\ea04';
}

.cm-icon-bag-50::before {
	content: '\ea05';
}

.cm-icon-bag-49::before {
	content: '\ea06';
}

.cm-icon-hospital-32::before {
	content: '\ea07';
}

.cm-icon-patch::before {
	content: '\ea08';
}

.cm-icon-pulse-chart::before {
	content: '\ea09';
}

.cm-icon-pill-42::before {
	content: '\ea0a';
}

.cm-icon-patch-34::before {
	content: '\ea0b';
}

.cm-icon-patch-34-2::before {
	content: '\ea0c';
}

.cm-icon-microscope::before {
	content: '\ea0d';
}

.cm-icon-tooth-o-sm::before {
	content: '\ea0e';
}

.cm-icon-bag-50-2::before {
	content: '\ea0f';
}

.cm-icon-bag-49-2::before {
	content: '\ea10';
}

.cm-icon-ambulance::before {
	content: '\ea11';
}

.cm-icon-tooth::before {
	content: '\ea12';
}

.cm-icon-patch-2::before {
	content: '\ea13';
}

.cm-icon-ambulance-2::before {
	content: '\ea14';
}

.cm-icon-ranking::before {
	content: '\ea15';
}

.cm-icon-chart-bar-33::before {
	content: '\ea16';
}

.cm-icon-edit-curves::before {
	content: '\ea17';
}

.cm-icon-s-pulse::before {
	content: '\ea18';
}

.cm-icon-logout::before {
	content: '\ea19';
}

.cm-icon-circle-04-2::before {
	content: '\ea1a';
}

.cm-icon-pencil::before {
	content: '\ea1b';
}

.cm-icon-pen-01::before {
	content: '\ea1c';
}

.cm-icon-pen::before {
	content: '\ea1d';
}

.cm-icon-pen-2::before {
	content: '\ea1e';
}

.cm-icon-dollar-bold::before {
	content: '\ea1f';
}

.cm-icon-dollar::before {
	content: '\ea20';
}

.cm-icon-shopping-cart::before {
	content: '\ea21';
}

.cm-icon-cart::before {
	content: '\ea22';
}

.cm-icon-cart-2::before {
	content: '\ea23';
}

.cm-icon-shopping-cart-2::before {
	content: '\ea24';
}

.cm-icon-shopping-cart-2-2::before {
	content: '\ea25';
}

.cm-icon-shopping-cart-2-3::before {
	content: '\ea26';
}

.cm-icon-circle-04::before {
	content: '\ea27';
}

.cm-icon-logout-o::before {
	content: '\ea28';
}

.cm-icon-plus-2::before {
	content: '\ea29';
}

.cm-icon-report::before {
	content: '\ea2a';
}

.cm-icon-plus::before {
	content: '\ea2b';
}

.cm-icon-close::before {
	content: '\ea2c';
}

.cm-icon-trash::before {
	content: '\ea2d';
}

.cm-icon-download-2::before {
	content: '\ea2e';
}

.cm-icon-download-o::before {
	content: '\ea2f';
}

.cm-icon-download::before {
	content: '\ea30';
}

.cm-icon-arrow-right::before {
	content: '\ea31';
}

.cm-icon-arrow-left::before {
	content: '\ea32';
}

.cm-icon-chevron-right::before {
	content: '\ea33';
}

.cm-icon-chevron-left::before {
	content: '\ea34';
}

.cm-icon-spreadsheet::before {
	content: '\ea35';
}

.cm-icon-csv-o::before {
	content: '\ea37';
}

.cm-icon-csv::before {
	content: '\ea38';
}

.cm-icon-pdf-o::before {
	content: '\ea39';
}

.cm-icon-pdf::before {
	content: '\ea3a';
}

.cm-icon-filter-organization::before {
	content: '\ea3b';
}

.cm-icon-close-sm::before {
	content: '\ea3c';
}

.cm-icon-trash-o::before {
	content: '\ea3d';
}

.cm-icon-close-bold::before {
	content: '\ea3e';
}

.cm-icon-hierarchy-53::before {
	content: '\ea3f';
}

.cm-icon-bulb::before {
	content: '\ea40';
}

.cm-icon-bulb-61::before {
	content: '\ea41';
}

.cm-icon-position-pin::before {
	content: '\ea42';
}

.cm-icon-pins::before {
	content: '\ea43';
}

.cm-icon-bulb-63::before {
	content: '\ea44';
}

.cm-icon-pig::before {
	content: '\ea45';
}

.cm-icon-categories::before {
	content: '\ea46';
}

.cm-icon-products::before {
	content: '\ea47';
}

.cm-icon-settings::before {
	content: '\ea48';
}

.cm-icon-speedometer::before {
	content: '\ea49';
}

.cm-icon-dashboard::before {
	content: '\ea4a';
}

.cm-icon-vendor::before {
	content: '\ea4b';
}

.cm-icon-vendor-o::before {
	content: '\ea4c';
}

.cm-icon-apartment::before {
	content: '\ea4d';
}

.cm-icon-family::before {
	content: '\ea4e';
}

.cm-icon-network-communication::before {
	content: '\ea4f';
}

.cm-icon-family-2::before {
	content: '\ea50';
}

.cm-icon-pin-user::before {
	content: '\ea51';
}

.cm-icon-p-share::before {
	content: '\ea52';
}

.cm-icon-house-search-engine::before {
	content: '\ea53';
}

.cm-icon-search::before {
	content: '\ea54';
}

.cm-icon-warning-o::before {
	content: '\ea55';
}

.cm-icon-invisible::before {
	content: '\ea56';
}

.cm-icon-warning::before {
	content: '\ea57';
}

.cm-icon-manufacturers-sm::before {
	content: '\ea58';
}

.cm-icon-visible::before {
	content: '\ea59';
}

.cm-icon-manufacturer::before {
	content: '\ea5a';
}

.cm-icon-manufacturer-o::before {
	content: '\ea5b';
}

.cm-icon-products-sm::before {
	content: '\ea5c';
}

.cm-icon-spreadsheet-sm::before {
	content: '\ea5d';
}

.cm-icon-search-4::before {
	content: '\ea5e';
}

.cm-icon-bulb-61-2::before {
	content: '\ea5f';
}

.cm-icon-h-dashboard::before {
	content: '\ea60';
}

.cm-icon-vendors-sm::before {
	content: '\ea61';
}

.cm-icon-search-3::before {
	content: '\ea62';
}

.cm-icon-syringe-4::before {
	content: '\ea63';
}

.cm-icon-syringe-sm::before {
	content: '\ea64';
}

.cm-icon-categories-sm::before {
	content: '\ea65';
}

.cm-icon-syringe-o::before {
	content: '\ea66';
}

.cm-icon-search-sm::before {
	content: '\ea67';
}

.cm-icon-spreadsheet-sm-2::before {
	content: '\ea68';
}

.cm-icon-refresh-01::before {
	content: '\ea69';
}

.cm-icon-menu-sm::before {
	content: '\ea6a';
}

.cm-icon-thumbs-up-o::before {
	content: '\ea6c';
}

.cm-icon-check-single-sm::before {
	content: '\ea6d';
}

.cm-icon-check-double-sm::before {
	content: '\ea6e';
}

.cm-icon-check-single::before {
	content: '\ea6f';
}

.cm-icon-check-double::before {
	content: '\ea70';
}

.cm-icon-cloud-upload::before {
	content: '\ea71';
}

.cm-icon-plus-sm::before {
	content: '\ea72';
}

.cm-icon-refresh::before {
	content: '\ea73';
}

.cm-icon-error-sm::before {
	content: '\ea74';
}

.cm-icon-error-o-sm::before {
	content: '\ea75';
}

.cm-icon-trash-sm::before {
	content: '\ea76';
}

.cm-icon-reset::before {
	content: '\ea77';
}

.cm-icon-circle-02::before {
	content: '\ea78';
}

.cm-icon-menu::before {
	content: '\ea79';
}

.cm-icon-arrow-up::before {
	content: '\ea7a';
}

.cm-icon-direction-up::before {
	content: '\ea7b';
}

.cm-icon-direction-down::before {
	content: '\ea7c';
}

.cm-icon-download-3::before {
	content: '\ea7d';
}

.cm-icon-plus-fat-sm::before {
	content: '\ea7e';
}

.cm-icon-ic_remove_circle_24px::before {
	content: '\ea7f';
}

.cm-icon-unlink::before {
	content: '\ea80';
}

.cm-icon-a-tag-remove::before {
	content: '\ea81';
}

.cm-icon-multiple::before {
	content: '\ea82';
}

.cm-icon-badge-13::before {
	content: '\ea83';
}

.cm-icon-folder-shared::before {
	content: '\ea84';
}

.cm-icon-ic_remove_circle_outline_24px::before {
	content: '\ea85';
}

.cm-icon-basket-simple-2::before {
	content: '\ea86';
}

.cm-icon-shopping-cart-2-4::before {
	content: '\ea87';
}

.cm-icon-arrow-down::before {
	content: '\ea88';
}

.cm-icon-basket-simple::before {
	content: '\ea89';
}

.cm-icon-upload::before {
	content: '\ea8a';
}

.cm-icon-cash-register::before {
	content: '\ea8b';
}

.cm-icon-store::before {
	content: '\ea8c';
}

.cm-icon-store-2::before {
	content: '\ea8d';
}

.cm-icon-cash-register-3::before {
	content: '\ea8e';
}

.cm-icon-cash-register-4::before {
	content: '\ea8f';
}

.cm-icon-delivery::before {
	content: '\ea90';
}

.cm-icon-delivery-2::before {
	content: '\ea91';
}

.cm-icon-delivery-fast::before {
	content: '\ea92';
}

.cm-icon-basket-simple-3::before {
	content: '\ea93';
}

.cm-icon-receipt-list-43::before {
	content: '\ea94';
}

.cm-icon-folder-money::before {
	content: '\ea95';
}

.cm-icon-m-settings::before {
	content: '\ea96';
}

.cm-icon-m-settings-2::before {
	content: '\ea97';
}

.cm-icon-m-settings-3::before {
	content: '\ea98';
}

.cm-icon-privacy-settings::before {
	content: '\ea99';
}

.cm-icon-m-settings-4::before {
	content: '\ea9a';
}

.cm-icon-ic_store_24px::before {
	content: '\ea9b';
}

.cm-icon-grid-2::before {
	content: '\ea9c';
}

.cm-icon-paragraph::before {
	content: '\ea9d';
}

.cm-icon-bullet-list-69::before {
	content: '\ea9e';
}

.cm-icon-bullet-list-67::before {
	content: '\ea9f';
}

.cm-icon-toggle::before {
	content: '\eaa0';
}

.cm-icon-chevron-down::before {
	content: '\eaa1';
}

.cm-icon-chevron-up::before {
	content: '\eaa2';
}

.cm-icon-new-construction::before {
	content: '\eaa3';
}

.cm-icon-new-construction-2::before {
	content: '\eaa4';
}

.cm-icon-ic_business_24px::before {
	content: '\eaa5';
}

.cm-icon-grid-view::before {
	content: '\eaa6';
}

.cm-icon-cash-register-2::before {
	content: '\eaa7';
}

.cm-icon-office-3::before {
	content: '\eaa8';
}

.cm-icon-party::before {
	content: '\eaa9';
}

.cm-icon-archive-content::before {
	content: '\eaaa';
}

.cm-icon-ic_local_shipping_24px::before {
	content: '\eaab';
}

.cm-icon-fav-list-2::before {
	content: '\eaac';
}

.cm-icon-fav-list-3::before {
	content: '\eaad';
}

.cm-icon-shopping-tag::before {
	content: '\eaae';
}

.cm-icon-tags-stack::before {
	content: '\eaaf';
}

.cm-icon-tags-stack-2::before {
	content: '\eab0';
}

.cm-icon-barcode::before {
	content: '\eab1';
}

.cm-icon-fav-list::before {
	content: '\eab2';
}

.cm-icon-office::before {
	content: '\eab3';
}

.cm-icon-office-2::before {
	content: '\eab4';
}

.cm-icon-barcode-scan::before {
	content: '\eab5';
}

.cm-icon-barcode-2::before {
	content: '\eab6';
}

.cm-icon-barcode-scan-2::before {
	content: '\eab7';
}

.cm-icon-skateboarding::before {
	content: '\eab8';
}

.cm-icon-content-delivery::before {
	content: '\eab9';
}

.cm-icon-content-delivery-2::before {
	content: '\eaba';
}

.cm-icon-house-search-engine-2::before {
	content: '\eabb';
}

.cm-icon-air-baloon::before {
	content: '\eabc';
}

.cm-icon-c-check::before {
	content: '\eabd';
}

.cm-icon-n-check::before {
	content: '\eabe';
}

.cm-icon-shape-oval::before {
	content: '\eabf';
}

.cm-icon-c-check-2::before {
	content: '\eac0';
}

.cm-icon-barcode-scan-3::before {
	content: '\eac1';
}

.cm-icon-roadmap::before {
	content: '\eac2';
}

.cm-icon-c-question::before {
	content: '\eac3';
}

.cm-icon-c-info::before {
	content: '\eac4';
}

.cm-icon-single-02::before {
	content: '\eac5';
}

.cm-icon-barcode-3::before {
	content: '\eac6';
}

.cm-icon-single-03::before {
	content: '\eac7';
}

.cm-icon-pin-4::before {
	content: '\eac8';
}

.cm-icon-circle-09::before {
	content: '\eac9';
}

.cm-icon-circle-08::before {
	content: '\eaca';
}

.cm-icon-circle-10::before {
	content: '\eacb';
}

.cm-icon-position-user::before {
	content: '\eacc';
}

.cm-icon-ic_perm_contact_calendar_48px::before {
	content: '\eacd';
}

.cm-icon-file-download-89::before {
	content: '\eace';
}

.cm-icon-archive-e-download::before {
	content: '\eacf';
}

.cm-icon-contacts-44-2::before {
	content: '\ead0';
}

.cm-icon-archive-paper-check-2::before {
	content: '\ead1';
}

.cm-icon-folder-remove::before {
	content: '\ead2';
}

.cm-icon-archive-paper-check::before {
	content: '\ead3';
}

.cm-icon-folder-heart::before {
	content: '\ead4';
}

.cm-icon-favorite::before {
	content: '\ead5';
}

.cm-icon-heart::before {
	content: '\ead6';
}

.cm-icon-heart-2::before {
	content: '\ead7';
}

.cm-icon-folder-heart-2::before {
	content: '\ead8';
}

.cm-icon-favorite-2::before {
	content: '\ead9';
}

.cm-icon-cart-favorite::before {
	content: '\eada';
}

.cm-icon-recipe-create::before {
	content: '\eadb';
}

.cm-icon-save-to-list::before {
	content: '\eadc';
}

.cm-icon-ic_playlist_add_24px::before {
	content: '\eadd';
}

.cm-icon-download-file::before {
	content: '\eade';
}

.cm-icon-recipe-create-3::before {
	content: '\eadf';
}

.cm-icon-recipe-create-2::before {
	content: '\eae0';
}

.cm-icon-browse::before {
	content: '\eae1';
}

.cm-icon-wand-2::before {
	content: '\eae2';
}

.cm-icon-wand::before {
	content: '\eae3';
}

.cm-icon-password::before {
	content: '\eae4';
}

.cm-icon-key::before {
	content: '\eae5';
}

.cm-icon-ic_vpn_key_24px::before {
	content: '\eae6';
}

.cm-icon-subtitles::before {
	content: '\eae7';
}

.cm-icon-subtitles-2::before {
	content: '\eae8';
}

.cm-icon-dots-to-close-2::before {
	content: '\eae9';
}

.cm-icon-d-remove::before {
	content: '\eaea';
}

.cm-icon-trash-can::before {
	content: '\eaeb';
}

.cm-icon-shape-star::before {
	content: '\eaec';
}

.cm-icon-receipt-list-43-2::before {
	content: '\eaed';
}

.cm-icon-book-open-2::before {
	content: '\eaee';
}

.cm-icon-e-reader::before {
	content: '\eaef';
}

.cm-icon-book-open-2-2::before {
	content: '\eaf0';
}

.cm-icon-server-rack::before {
	content: '\eaf1';
}

.cm-icon-server-rack-2::before {
	content: '\eaf2';
}

.cm-icon-restaurant-menu::before {
	content: '\eaf3';
}

.cm-icon-restaurant-menu-2::before {
	content: '\eaf4';
}

.cm-icon-link::before {
	content: '\eaf5';
}

.cm-icon-newsletter-2::before {
	content: '\eaf6';
}

.cm-icon-letter::before {
	content: '\eaf7';
}

.cm-icon-ic_email_24px::before {
	content: '\eaf8';
}

.cm-icon-phone-call::before {
	content: '\eaf9';
}

.cm-icon-ic_local_phone_24px::before {
	content: '\eafa';
}

.cm-icon-animation-14::before {
	content: '\eafb';
}

.cm-icon-pc-play-media::before {
	content: '\eafc';
}

.cm-icon-pc-play-media-2::before {
	content: '\eafd';
}

.cm-icon-video-playlist-2::before {
	content: '\eaff';
}

.cm-icon-wireless-charging::before {
	content: '\eb00';
}

.cm-icon-roadmap-2::before {
	content: '\eb01';
}

.cm-icon-progress-indicator::before {
	content: '\eb02';
}

.cm-icon-clock::before {
	content: '\eb03';
}

.cm-icon-time::before {
	content: '\eb04';
}

.cm-icon-time-2::before {
	content: '\eb05';
}

.cm-icon-image-delete::before {
	content: '\eb06';
}

.cm-icon-image-delete-2::before {
	content: '\eb07';
}

.cm-icon-add-like::before {
	content: '\eb08';
}

.cm-icon-cart-add::before {
	content: '\eb09';
}

.cm-icon-cart-add-2::before {
	content: '\eb0a';
}

.cm-icon-shape-star-2::before {
	content: '\eb0b';
}

.cm-icon-add-like-2::before {
	content: '\eb0c';
}

.cm-icon-pen-01-2::before {
	content: '\eb0d';
}

.cm-icon-edit::before {
	content: '\eb0e';
}

.cm-icon-small-triangle-down::before {
	content: '\eb0f';
}

.cm-icon-dots::before {
	content: '\eb10';
}

.cm-icon-d-edit::before {
	content: '\eb11';
}

.cm-icon-paper::before {
	content: '\eb12';
}

.cm-icon-cheque::before {
	content: '\eb13';
}

.cm-icon-c-delete::before {
	content: '\eb14';
}

.cm-icon-ban::before {
	content: '\eb15';
}

.cm-icon-ban-2::before {
	content: '\eb16';
}

.cm-icon-send::before {
	content: '\eb17';
}

.cm-icon-send-2::before {
	content: '\eb18';
}

.cm-icon-route-alert::before {
	content: '\eb19';
}

.cm-icon-real-estate::before {
	content: '\eb1a';
}

.cm-icon-attachment::before {
	content: '\eb1b';
}

.cm-icon-check::before {
	content: '\eb1c';
}

.cm-icon-jpg::before {
	content: '\eb1d';
}

.cm-icon-jpg-2::before {
	content: '\eb1e';
}

.cm-icon-padlock::before {
	content: '\eb1f';
}

.cm-icon-png-2::before {
	content: '\eb20';
}

.cm-icon-pdf-2::before {
	content: '\eb21';
}

.cm-icon-pdf-3::before {
	content: '\eb22';
}

.cm-icon-route-alert-2::before {
	content: '\eb23';
}

.cm-icon-file-xlsx-2::before {
	content: '\eb24';
}

.cm-icon-csv-2::before {
	content: '\eb25';
}

.cm-icon-csv-3::before {
	content: '\eb26';
}

.cm-icon-docx::before {
	content: '\eb27';
}

.cm-icon-privacy::before {
	content: '\eb28';
}

.cm-icon-delivery-3::before {
	content: '\eb29';
}

.cm-icon-delivery-4::before {
	content: '\eb2a';
}

.cm-icon-comment::before {
	content: '\eb2b';
}

.cm-icon-f-comment::before {
	content: '\eb2c';
}

.cm-icon-b-comment::before {
	content: '\eb2d';
}

.cm-icon-comment-2::before {
	content: '\eb2e';
}

.cm-icon-f-comment-2::before {
	content: '\eb2f';
}

.cm-icon-comment-3::before {
	content: '\eb30';
}

.cm-icon-b-comment-2::before {
	content: '\eb31';
}

.cm-icon-png::before {
	content: '\eb32';
}

.cm-icon-web-hyperlink::before {
	content: '\eb33';
}

.cm-icon-a-tag::before {
	content: '\eb34';
}

.cm-icon-web-hyperlink-2::before {
	content: '\eb35';
}

.cm-icon-url::before {
	content: '\eb36';
}

.cm-icon-chain::before {
	content: '\eb37';
}

.cm-icon-hyperlink::before {
	content: '\eb38';
}

.cm-icon-receipt::before {
	content: '\eb39';
}

.cm-icon-receipt-2::before {
	content: '\eb3a';
}

.cm-icon-book-bookmark-2::before {
	content: '\eb3b';
}

.cm-icon-agenda::before {
	content: '\eb3c';
}

.cm-icon-book-open::before {
	content: '\eb3d';
}

.cm-icon-book-open-3::before {
	content: '\eb3e';
}

.cm-icon-book-open-4::before {
	content: '\eb3f';
}

.cm-icon-file-xlsx::before {
	content: '\eb40';
}

.cm-icon-docx-2::before {
	content: '\eb41';
}

.cm-icon-web-url::before {
	content: '\eb42';
}

.cm-icon-t-warning::before {
	content: '\eb43';
}
