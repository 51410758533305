.link-danger {
    color: $danger-color !important;
    &:focus,
    &:active,
    &:hover {
        color: $danger-states-color !important;
    }
}
.link-info {
    color: $info-color !important;
    &:focus,
    &:active,
    &:hover {
        color: $info-states-color !important;
    }
}

.landing-alert {
    margin-bottom: 0;
}
.landing-header {
    background-size: cover;
    min-height: 570px;
}

.landing-header .motto {
    padding-top: 8%;
    text-align: left;
    z-index: 3;
}
.landing-section {
    padding: 100px 0;
}
.landing-section .btn-simple {
    padding: 0;
}
.landing-section .column {
    padding: 0 75px 0 25px;
}
.team-player .img-circle,
.team-player .img-thumbnail {
    display: block;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
}
.contact-form {
    margin-top: 30px;
}
.contact-form label {
    margin-top: 15px;
}
.contact-form .btn {
    margin-top: 30px;
}

.navbar-relative {
    position: relative;
    z-index: 2;
}

#register-navbar a {
    color: #fff;
}

.register-footer {
    bottom: 20px;
    color: #fff;
    position: absolute;
    z-index: 3;
    width: 100%;
}
.register-footer .fa-heart {
    color: #eb5e28;
}
.register-card {
    background-color: #ff8f5e;
    border-radius: 8px;
    color: #fff;
    max-width: 350px;
    margin: 20px 0 70px;
    min-height: 400px;
    padding: 30px;
}
.register-card label {
    margin-top: 15px;
}
.register-card .title {
    color: #b33c12;
    text-align: center;
}
.register-card .btn {
    margin-top: 30px;
}
.register-card .forgot {
    text-align: center;
}
.register-page {
    .card-register {
        background-color: $white-color;

        .card-title {
            color: #66615b;
        }
    }
}

.full-screen {
    #register-navbar a {
        color: #fff;
    }
    .page-header .container {
        margin-top: 11%;
        position: relative;
        z-index: 3;
    }
    .demo-footer {
        bottom: 10px;
        left: 0;
        color: #fff;
        position: absolute;
        z-index: 3;
        width: 100%;
    }
    .demo-footer .fa-heart {
        color: #eb5e28;
    }
    .demo-card {
        border-radius: 8px;
        max-width: 350px;
        margin: 20px 0;
        min-height: 400px;
        padding: 30px;
    }

    &.login {
        .demo-card {
            background-color: #ff8f5e;
            color: #fff;

            label {
                margin-top: 15px;
            }
            .title {
                color: #b33c12;
                text-align: center;
            }
            .btn {
                margin-top: 30px;
            }
            .forgot {
                text-align: center;
            }
        }
    }
    &.register-page {
        .page-header {
            .container {
                margin-bottom: 50px;

                .info-horizontal .icon {
                    margin-top: 10px;
                }
            }
        }

        .demo-card,
        .card-register {
            background-color: $white-color;
            max-width: 360px;

            input {
                margin-bottom: 8px;
            }
            .btn {
                margin-top: 20px;
            }
            .division {
                float: none;
                margin: 0 auto;
                overflow: hidden;
                margin: 20px 0;
                position: relative;
                text-align: center;
                width: 100%;
                color: $font-color;
                .line {
                    border-top: 1px solid rgba(0, 0, 0, 0.2);
                    position: absolute;
                    top: 10px;
                    width: 40%;
                    &.l {
                        left: 0;
                    }
                    &.r {
                        right: 0;
                    }
                }
            }
            .register-form {
                .form-control {
                    border: 1px solid #ccc5b9 !important;
                }
            }
            .login p {
                color: $font-color;
                a {
                    color: $info-color;
                }
            }
        }
        .social {
            margin-bottom: 10px;
            margin-top: 15px;
            text-align: center;
        }
        .login {
            margin-top: 20px;
            text-align: center;
            p {
                font-size: 0.9em;
            }
        }
    }
}
#carousel {
    max-width: 385px;
    .card {
        .carousel-item.active {
            display: block !important;
        }
        .carousel-control:focus {
            color: $white-color;
        }
    }
}
.contact-page,
.discover,
.search-page {
    .section:first-of-type {
        padding-top: 100px;
    }
}
.profile-background {
    background-position: center center;
    background-size: cover;
    height: 300px;
    position: relative;
    // margin-top: -110px;
}
.profile-background .filter-black::after {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    display: block;
    height: 300px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.profile-content {
    position: relative;
}
.owner .avatar {
    margin-top: -85px;
    padding: 15px;
    position: relative;
    z-index: 3;
}
.owner .name h4 {
    margin-top: 10px;
}
.profile-tabs {
    margin: 50px 0;
    min-height: 300px;
}
#following h3 {
    margin: 40px 0;
}
.follows .unfollow {
    width: 15px;
}
.follows hr {
    margin-top: 10px;
}
.alert h5 {
    margin-bottom: 0;
}

// theme changes for settings page
.settings-page,
.profile-page {
    .settings-form {
        margin-bottom: 50px;
        margin-top: 50px;
    }
    .fileinput {
        margin-top: -140px;
        position: relative;
        z-index: 3;
    }
    .form-group {
        padding-bottom: 10px;
    }
}
.notifications {
    list-style: none;
    padding-left: 0;
    padding-top: 20px;
    .notification-item {
        border-top: 1px solid #e8e7e3;
        min-height: 60px;
        padding-top: 20px;
        padding-bottom: 15px;
        .switch {
            float: right;
        }
        .bootstrap-switch {
            float: right;
        }
    }
}

.about-us {
    .container {
        max-width: 970px;
    }
    .header-wrapper {
        height: 550px;
    }
    .section {
        padding-top: 50px;
    }
    h3.title-uppercase {
        margin-top: 50px;
    }
    p {
        margin-top: 20px;
    }
    .creators {
        margin-top: 100px;
    }
    .more-info {
        margin-top: 50px;
        margin-bottom: 30px;
    }
}
.discover {
    background-color: $bg-nude;
    .discover-title {
        margin-top: 20px;
    }
    .form-inline {
        margin: 20px 0 40px 165px;
    }
    .items-row {
        margin-bottom: 50px;
    }
    .preloader {
        h5 {
            color: $preloader-color;
            display: inline-block;
        }
    }
    .navbar {
        border-radius: 0;
    }
}
.add-product {
    background-color: $bg-nude;
    .container {
        max-width: 970px;
    }
    h6 {
        color: $dark-gray;
        margin-top: 20px;
    }
    .price-row {
        margin-bottom: 30px;
    }
    .buttons-row {
        margin: 60px 0 20px;
        .btn {
            margin-bottom: 15px;
        }
    }
    .display-checkbox {
        margin-top: 40px;
    }
}
.product-page {
    background-color: #f4f3ef;
    .container {
        max-width: 970px;
    }
    .title-row {
        margin-bottom: 30px;
    }
    .shop {
        margin-top: 0px;
    }
    .carousel-inner {
        background-color: transparent;
        .item {
            padding: 40px 30px;
        }
    }
    .price {
        margin-top: 10px;
    }
    .shipping {
        margin-bottom: 40px;
    }
    .details-row {
        margin-top: 30px;
    }
    .faq {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .add-row {
        margin-top: 50px;
        margin-bottom: 50px;
        h4 {
            margin-top: 10px;
        }
    }
}
.contact-page {
    .navbar {
        margin-bottom: 0px;
    }
    .title {
        margin-top: 40px;
    }
    .contact {
        .form-control {
            margin-bottom: 20px;
        }
    }
    .visit {
        margin-top: 40px;
        margin-bottom: 10px;
    }
    .big-map {
        height: 300px;
        width: 100%;
    }
    .footer-demo {
        background-color: #f4f3ef;
    }
}
.blog,
.blog-post {
    background-color: $white-color;
    .navbar {
        border-bottom: 0 none;
        margin-bottom: 0px;

        input {
            background-color: $white-color;
        }
    }
    .title {
        h3 {
            margin-top: -10px;
        }
    }
    .main-tag {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .title {
        color: $default-states-color;
        font-weight: 600;
    }

    .card-big-shadow {
        max-width: 100%;
    }
    .card {
        margin-top: 25px;
        background-size: cover;
        min-height: 430px;
        width: 100%;
    }
    .image-thumb {
        font-size: 14px;
        color: $muted-color;
    }
}
.card-blog {
    a {
        .title-uppercase {
            margin-bottom: 10px;
        }
    }
}
.blog-posts {
    .article {
        .article-content {
            p {
                margin-top: 10px;
            }
            .btn {
                margin-top: 10px;
            }
        }
        .card-blog {
            .btn {
                margin-bottom: 20px;
            }
        }
    }
}
.blog-post {
    .article {
        .article-content {
            h4 {
                color: #403d39;
                font-weight: bold;
                margin-top: 40px;
                text-align: center;
            }
            p {
                margin-top: 20px;
            }
            ul > li {
                margin: 10px 0px;
            }
        }
        .article-footer {
            margin-top: 50px;
            .sharing {
                margin-bottom: 100px;
                float: right;
                h5 {
                    margin-top: 0px !important;
                }
            }
        }
        .media {
            .media-body {
                .btn {
                    margin-top: 50px;
                }
                p {
                    padding-right: 150px;
                }
            }
            .big-avatar {
                width: 100px;
                height: 100px;
            }
        }
    }
    .related-articles {
        margin-bottom: 50px;
        margin-top: 80px;
        text-align: center;
        img {
            height: 230px;
            width: 100%;
        }
        .blog-title {
            margin-top: 15px;
        }
    }
    .blog-header-image {
        background-image: url('{{ path-general }}/assets/img{{ site.product_path }}/farid-askerov.jpg');
    }
    .navbar {
        input {
            background-color: rgba(255, 255, 255, 0.17);
        }
    }
}
.search-page {
    background-color: $white-color;
    .navbar-transparent {
        border-bottom: 0 none;
        margin-bottom: 0px;
        .navbar-brand {
            color: $font-color;
        }
        .navbar-toggle {
            .icon-bar {
                background: $font-color;
            }
        }
    }

    .section-search {
        min-height: 100vh;
    }
    .addon-xtreme {
        background-color: $white-color;
        font-size: 2.5em;
        color: $muted-color;
        position: absolute;
        top: 6px;
        left: 10px;
    }
    .input-xtreme {
        background-color: $white-color;
        font-size: 3em;
        height: 70px;
        margin-left: 70px;
        padding-left: 0px;
    }
    .follows {
        li {
            padding: 20px;
        }
    }
    .search-form {
        margin: 0 auto 50px;
        max-width: 360px;

        .fa {
            width: 22px;
        }
    }
    .text-missing {
        margin-top: 100px;
        margin-bottom: 50px;
    }
    .description {
        text-align: left;
        h5 {
            margin-top: 0px;
            small {
                font-weight: $font-weight-bold;
            }
        }
    }
}

// twitter pages
.twitter-redesign {
    .nav-tabs > li.active *::after {
        border-bottom: 11px solid #f9f9f7;
    }

    .twitter-logo {
        position: absolute;
        left: 50%;
        margin-left: -40px;
        a,
        a:hover,
        a:active,
        a:focus {
            i {
                color: #55acee;
                font-size: 30px;
            }
        }
    }
    .navbar-form {
        margin-right: 0px;
        padding-right: 0px;
    }
    .following {
        margin-top: -45px;
        margin-left: 75px;
    }
    .hashtag-suggestions {
        li {
            margin-bottom: 5px;
        }
    }
    .account {
        margin-bottom: 15px;
        .description-section {
            line-height: 1.2em;
            margin-top: 5px;
            padding: 0px;
        }
        .follow {
            margin-top: 10px;
        }
    }
    .tweets {
        padding: 20px 50px;
        .media {
            border-bottom: 1px solid #f1eae0;
            overflow: visible;

            img {
                width: 100%;
            }
            .avatar {
                border: 0 none;
                margin-top: 10px;
                .btn-just-icon {
                    line-height: 30px;
                }
            }
            .media-heading {
                margin-bottom: 5px;
            }
            .media-body {
                overflow: visible;
            }
            .media-footer {
                .btn {
                    font-weight: bold;
                    margin-right: 20px;
                }
            }
            .img-tweet {
                display: inline-block;
            }
            .retweet {
                margin-top: -20px;
                margin-left: 40px;
            }
            .tweet-link {
                margin-bottom: 25px;
                margin-top: 20px;
            }
            .img-tweet-link {
                display: inline-block;
                // max-width: 180px;
            }
            &.last-media {
                border-bottom: 0px;
            }
            .dropup,
            .dropdown {
                display: inline;
            }
            .dropdown-menu {
                &:before {
                    border-bottom: 11px solid $medium-pale-bg;
                    border-left: 11px solid rgba(0, 0, 0, 0);
                    border-right: 11px solid rgba(0, 0, 0, 0);
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: 12px;
                    top: -11px;
                }
                &:after {
                    border-bottom: 11px solid $pale-bg;
                    border-left: 11px solid rgba(0, 0, 0, 0);
                    border-right: 11px solid rgba(0, 0, 0, 0);
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: 12px;
                    top: -10px;
                }
            }
            .dropdown-menu-right {
                right: 11px !important;
            }
        }
    }
    .nav-pills-navigation {
        text-align: center;
        .nav-pills-wrapper {
            display: inline-block;
            position: relative;
            width: auto;
        }
    }
    .people {
        .number {
            margin-top: 15px;
        }
        .card-user {
            .avatar {
                margin-bottom: 10px;
            }
            .image {
                height: 120px;
            }
            .content {
                min-height: 255px;
                .description {
                    font-size: 15px;
                }
            }
        }
        .people-list {
            margin-top: 40px;
        }
    }
    #media {
        img.vertical-image {
            max-height: 240px;
        }
        .gallery-item .gallery-caption {
            display: none;
        }
        .pswp__caption__center {
            max-width: 800px;
        }
        .pswp__caption {
            h5 {
                display: inline-block;
            }
        }
    }
}
.ecommerce-page {
    .container {
        max-width: 970px;
    }
    .header-wrapper {
        height: 550px;
    }
    .latest-offers {
        .details {
            .name {
                margin-top: 20px;
            }
            .meta {
                color: #ccc5b9;
                font-size: 0.8em;
                font-weight: normal;
            }
            .price {
                padding-bottom: 15px;
                padding-top: 15px;
            }
        }
        .section-title {
            margin-bottom: 30px;
        }
    }
    .card-refine {
        margin-top: 30px;
    }
    .products {
        margin-top: 73px;
        .card-product {
            margin-bottom: 50px;
        }
    }
    .article-image {
        margin-top: 25px;

        .card-big-shadow {
            max-width: 100%;
        }
        .card {
            background-size: cover;
            min-height: 230px;
            width: 100%;
        }
        .image-thumb {
            font-size: 14px;
            color: $muted-color;
        }
    }
}
.documentation {
    .filter:after {
        height: 750px !important;
    }
}

// ERROR PAGES (400, 422, 500)

body {
    overflow-x: hidden;
    width: 100%;
}
.background-img {
    height: 100vh;
    background-position: center center;
    background-size: cover;

    .filter:after {
        background-color: rgba(0, 0, 0, 0.5);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .social-buttons {
        .btn:nth-child(2) {
            margin-left: 15px;
            margin-right: 15px;
        }
    }

    .title {
        margin: 0 auto;
        margin-top: 15%;
        margin-bottom: 30px;
        font-size: 10em;
        color: #ffffff;
        z-index: 2;
        text-align: center;
    }
    .card {
        &.card-just-text {
            .card-body {
                padding: 50px 30px;
            }
        }
        .icon {
            padding: 0;
            margin-top: 30px;
            margin-bottom: -30px;
            i {
                height: 90px;
                width: 90px;
                font-size: 35px;
            }
        }
        .card-text {
            padding-bottom: 0;
            margin-bottom: -20px;
        }
    }

    .discover-pages {
        margin: 0 auto;
        color: #fff;
        z-index: 2;
        padding-bottom: 20px;
    }
    .error-msg {
        text-align: center;
    }
}
