@mixin input-size($padding-vertical, $padding-horizontal, $height) {
    padding: $padding-vertical $padding-horizontal;
    height: $height;
}

@mixin light-form() {
    border-radius: 0;
    border: 0;
    padding: 0;
    background-color: transparent;
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}
@mixin placeholder() {
    @include optional-at-root('::-moz-placeholder') {
        @content;
    } // Firefox
    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    } // Internet Explorer 10+
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    } // Safari and Chrome
}
