@import './bootstrap/functions.scss';

$primary: #0071b8;
$primary-color: $primary;

$info: #19c6f7;
$info-color: $info;

$secondary: #e2e3e5;
$secondary-color: $secondary;

$success: #2a8d4b;
$success-color: $success;

$danger: #d23f29;
$danger-color: $danger;

$warning: #e7ae1b;
$warning-color: $warning;

$theme-colors: (
	'primary': $primary,
	'info': $info,
	'secondary': $secondary,
	'success': $success,
);

$font-size-base: 0.85rem;

@import './bootstrap/bootstrap.scss';
@import './paper-kit.scss';
