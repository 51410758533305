.card-background {
    background-position: center center;
    background-size: cover;
    text-align: center;

    .card-body {
        position: relative;
        z-index: 2;
        min-height: 370px;
        max-width: 530px;
        margin: 0 auto;
        padding-top: 60px;
        padding-bottom: 60px;
    }

    &.card-background-product .card-body {
        max-width: 400px;
        .card-title {
            margin-top: 30px;
        }
    }

    .stats {
        color: $white-color;
    }

    .card-footer {
        .stats-link > a {
            color: $white-color;
            line-height: 1.9;
        }
    }

    .category,
    .card-description,
    small {
        color: #fff;
    }

    .card-title {
        color: $white-color;
        margin-top: 130px;
    }

    &:not(.card-pricing) .btn {
        margin-bottom: 0;
    }

    &:after {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: '';
        background-color: rgba(0, 0, 0, 0.63);
        border-radius: $border-radius-large;
    }
}
