//animations

.icon-property {
    @include transition($slow-transition-time, $transition-bezier);
    position: relative;
    display: inline-block;
}

#animated-buttons {
    .btn {
        i {
            position: relative;
            margin-top: -3px;
        }
    }
}

.btn-rotate {
    i {
        @extend .icon-property;
    }

    &:hover,
    &:focus {
        i {
            @include rotate-53();
        }
    }
}

.btn-magnify {
    i {
        @extend .icon-property;
    }

    &:hover,
    &:focus {
        i {
            @include transform-scale(1.22);
        }
    }
}

.btn-move-left {
    i {
        @extend .icon-property;
        margin-right: 0;
    }

    &:hover,
    &:focus {
        i {
            @include transform-translate-x(-5px);
        }
    }
}

.btn-move-right {
    i {
        @extend .icon-property;
        margin-right: 0;
    }

    &:hover,
    &:focus {
        i {
            @include transform-translate-x(5px);
        }
    }
}
