/*! nouislider - 13.1.1 - 2/14/2019 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.noUi-target {
    position: relative;
    direction: ltr;
}
.noUi-base,
.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
    overflow: hidden;
    z-index: 0;
}
.noUi-connect,
.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}
/* Offset direction
 */
html:not([dir='rtl']) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
    width: 0;
}
.noUi-horizontal .noUi-origin {
    height: 0;
}
.noUi-handle {
    position: absolute;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
    -webkit-transition: transform 0.3s;
    transition: transform 0.3s;
}
.noUi-state-drag * {
    cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
    height: 1px;
}
.noUi-horizontal .noUi-handle {
    border-radius: 50%;
    background-color: $white-color;
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.2);
    height: 15px;
    width: 15px;
    cursor: pointer;
    margin-left: -10px;
    margin-top: -7px;
}
.noUi-vertical {
    width: 18px;
}
.noUi-vertical .noUi-handle {
    width: 28px;
    height: 34px;
    left: -6px;
    top: -17px;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
    right: -15px;
    left: auto;
    outline: none;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
    background-color: rgba(182, 182, 182, 0.3);
    border-radius: 3px;
}
.noUi-connects {
    border-radius: 3px;
}
.noUi-connect {
    background: $default-color;
    border-radius: 3px;
    -webkit-transition: background 450ms;
    transition: background 450ms;
}
/* Handles and cursors;
 */
.noUi-draggable {
    cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
    cursor: ns-resize;
}
.noUi-handle {
    border-radius: 3px;
    background: #fff;
    cursor: default;
    box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
    -webkit-transition: $general-transition-time $transition-ease;
    -moz-transition: $general-transition-time $transition-ease;
    -ms-transition: $general-transition-time $transition-ease;
    -o-transform: $general-transition-time $transition-ease;
    transition: $general-transition-time $transition-ease;
}
.noUi-active {
    -webkit-transform: scale3d(1.5, 1.5, 1);
    -moz-transform: scale3d(1.5, 1.5, 1);
    -ms-transform: scale3d(1.5, 1.5, 1);
    -o-transform: scale3d(1.5, 1.5, 1);
    transform: scale3d(1.5, 1.5, 1);
}

/* Disabled state;
 */
[disabled] .noUi-connect {
    background: #b8b8b8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
    cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.noUi-pips {
    position: absolute;
    color: #999;
}
/* Values;
 *
 */
.noUi-value {
    position: absolute;
    white-space: nowrap;
    text-align: center;
}
.noUi-value-sub {
    color: #ccc;
    font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
    position: absolute;
    background: #ccc;
}
.noUi-marker-sub {
    background: #aaa;
}
.noUi-marker-large {
    background: #aaa;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
    padding: 10px 0;
    height: 80px;
    top: 100%;
    left: 0;
    width: 100%;
}
.noUi-value-horizontal {
    -webkit-transform: translate(-50%, 50%, 0);
    transform: translate(-50%, 50%, 0);
}
.noUi-rtl .noUi-value-horizontal {
    -webkit-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
    height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%;
}
.noUi-value-vertical {
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
    padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
    -webkit-transform: translate(0, 50%);
    transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
    width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
    width: 15px;
}
.noUi-tooltip {
    display: block;
    position: absolute;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background: #fff;
    color: #000;
    padding: 5px;
    text-align: center;
    white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    left: 50%;
    bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    right: 120%;
}

.slider {
    &.slider-neutral {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $white-color;
        }

        &.noUi-target {
            background-color: rgba(255, 255, 255, 0.3);
        }

        & .noUi-handle {
            background-color: $white-color;
        }
    }

    &.slider-primary {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $primary-color;
        }

        &.noUi-target {
            background-color: $light-azure;
        }

        & .noUi-handle {
            background-color: $primary-color;
            box-shadow: 0px 0px 10px 0px $primary-color;
        }
    }

    &.slider-info {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $info-color;
        }

        &.noUi-target {
            background-color: $light-blue;
        }

        & .noUi-handle {
            background-color: $info-color;
            box-shadow: 0px 0px 10px 0px $info-color;
        }
    }
    &.slider-success {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $success-color;
        }

        &.noUi-target {
            background-color: $light-green;
        }

        & .noUi-handle {
            background-color: $success-color;
            box-shadow: 0px 0px 10px 0px $success-color;
        }
    }
    &.slider-warning {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $warning-color;
        }

        &.noUi-target {
            background-color: $light-orange;
        }

        & .noUi-handle {
            background-color: $warning-color;
            box-shadow: 0px 0px 10px 0px $warning-color;
        }
    }
    &.slider-danger {
        & .noUi-connect,
        &.noUi-connect {
            background-color: $danger-color;
        }

        &.noUi-target {
            background-color: $light-red;
        }

        & .noUi-handle {
            background-color: $danger-color;
            box-shadow: 0px 0px 10px 0px $danger-color;
        }
    }
}
