// width: 100%;
@-webkit-keyframes uil-reload-css {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes uil-reload-css {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes uil-reload-css {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-ms-keyframes uil-reload-css {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes uil-reload-css {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes uil-reload-css {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes uil-reload-css {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes uil-reload-css {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.uil-reload-css {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    vertical-align: middle;

    > div {
        -ms-animation: uil-reload-css 1.4s linear infinite;
        -moz-animation: uil-reload-css 1.4s linear infinite;
        -webkit-animation: uil-reload-css 1.4s linear infinite;
        -o-animation: uil-reload-css 1.4s linear infinite;
        animation: uil-reload-css 1.4s linear infinite;
        display: block;
        position: absolute;
        top: 18px;
        left: 18px;
        width: 45px;
        height: 45px;
        border-radius: 100px;
        border: 7px solid $preloader-color;
        border-top: 7px solid rgba(0, 0, 0, 0);
        border-right: 7px solid $preloader-color;
        border-bottom: 7px solid $preloader-color;
    }

    > div:after {
        content: ' ';
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 8px 8px 8px;
        border-color: transparent transparent $preloader-color transparent;
        display: block;
        -ms-transform: translate(-3px, -5px) rotate(45deg);
        -moz-transform: translate(-3px, -5px) rotate(45deg);
        -webkit-transform: translate(-3px, -5px) rotate(45deg);
        -o-transform: translate(-3px, -5px) rotate(45deg);
        transform: translate(-3px, -5px) rotate(45deg);
    }
}

.uil-reload-css.reload-small {
    height: 20px;
    width: 20px;

    > div {
        top: 0;
        left: 0;
        border: 3px solid $preloader-color;
        border-top: 3px solid rgba(0, 0, 0, 0);
        border-right: 3px solid $preloader-color;
        border-bottom: 3px solid $preloader-color;
        height: 20px;
        width: 20px;
    }

    > div:after {
        border-width: 0 5px 5px 5px;
        -ms-transform: translate(-2px, -3px) rotate(45deg);
        -moz-transform: translate(-2px, -3px) rotate(45deg);
        -webkit-transform: translate(-2px, -3px) rotate(45deg);
        -o-transform: translate(-2px, -3px) rotate(45deg);
        transform: translate(-2px, -3px) rotate(45deg);
    }
}
.uil-reload-css.reload-background {
    background-color: $medium-gray;
    border-radius: 50%;

    > div {
        border: 7px solid $white-color;
        border-top: 7px solid rgba(0, 0, 0, 0);
        border-right: 7px solid $white-color;
        border-bottom: 7px solid $white-color;
    }

    > div:after {
        border-color: transparent transparent $white-color transparent;
    }
}
.uil-reload-css.reload-background.reload-small {
    height: 40px;
    width: 40px;

    > div {
        left: 10px;
        top: 10px;
        border: 3px solid $white-color;
        border-top: 3px solid rgba(0, 0, 0, 0);
        border-right: 3px solid $white-color;
        border-bottom: 3px solid $white-color;
    }
}
