#cd-vertical-nav {
    position: fixed;
    right: -78px;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 4;
    ul {
        list-style: none;
        padding: 0;
    }
    li {
        text-align: right;
    }
    a {
        display: inline-block;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        width: 100%;

        span {
            float: right;
            display: inline-block;
            -webkit-transform: scale(0.6);
            -moz-transform: scale(0.6);
            -ms-transform: scale(0.6);
            -o-transform: scale(0.6);
            transform: scale(0.6);
        }

        &:hover {
            .cd-label {
                opacity: 1;
                left: -90%;
                -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
                -moz-transition: -moz-transform 0.2s, opacity 0.2s;
                transition: transform 0.2s, opacity 0.2s;
            }
            span {
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }
        }

        &.is-selected {
            .cd-dot {
                background-color: $primary-color;
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
                transform: scale(1);
            }
        }

        &:after {
            content: '';
            clear: both;
            display: table;
        }
    }
    .cd-dot {
        position: relative;
        top: 8px;
        right: calc(100% - 15px);
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $danger-color;
        -webkit-transition: -webkit-transform 0.2s, background-color 0.5s;
        -moz-transition: -moz-transform 0.2s, background-color 0.5s;
        transition: transform 0.2s, background-color 0.5s;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }
    .cd-label {
        position: relative;
        margin-right: 10px;
        padding: 7px 14px;
        color: white;
        background: rgba(0, 0, 0, 0.53);
        font-size: 10px;
        border-radius: 20px;
        text-transform: uppercase;
        font-weight: 600;
        opacity: 0;
        -webkit-transform-origin: 100% 50%;
        -moz-transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
        -o-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
    }
}

.header-2 {
    .header {
        .card-body {
            padding: 20px 20px 5px 20px;
        }
    }
}
.header-wrapper {
    .header {
        height: 100vh;
        margin: 0;
        padding: 0;
        border: 0;
        background-position: center center;
        background-size: cover;
        .filter::after {
            height: 100%;
        }
        .upper-container {
            margin-top: 18%;
            color: white;
        }
    }
}

.header-3 {
    .carousel {
        .carousel-inner {
            .carousel-item img {
                width: 100%;
                height: 100vh;
                border-radius: 0 !important;
            }
            .carousel-caption {
                bottom: 30% !important;
            }
        }
        .navbar-absolute {
            position: absolute;
            width: 100%;
            padding-top: 10px;
            z-index: 9;
        }

        .carousel-control-prev {
            margin-left: 35px;
        }
        .carousel-control-next {
            margin-right: 35px;
        }
    }

    .header {
        .filter:after {
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            left: 0;
            top: 0;
            content: '';
        }
    }
}

.iframe-container iframe {
    width: 100%;
}
