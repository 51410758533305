:root {
	--color-red: #d00011;
	--color-brand-bg-blue: #d4f4ff;
	--color-brand-border-blue: #19c6f7;
	--color-brand-blue: var(--primary);
	--color-brand-green: #26e077;
	--color-brand-navy: #0c384f;
	--color-brand-aqua: #56e8dd;
	--color-brand-medium-blue: #0089dd;
	--color-brand-warning: #fbd27b;
	--color-brand-warning-text: #846421;
	--color-light-grey: #f9f9f9;
	--color-table-hover: #f0f0f0;
	--color-gray: #828282;
	--main-bg-color: rgb(248 250 252);
	--text-color-header: #9ca7a9;
	--text-color-header-hover: #7a8587;
	--text-color-normal: #212529;
	--text-color-lighter: #888;
	--sidenav-bg-color: #f5f5f5;
	--subtitle-color: #999;
	--disabled-grey: #d0d0d0;
	--link-color: #0071b8;
	--link-color-hover: #37addd;
	--overlay-gray: rgba(50, 50, 60, 0.6);
	--dropzone-border-width: 4px;
	--spacing-normal: 20px;
	--spacing-half: 10px;
	--spacing-double: 40px;
	--divider-line-color: #f0f0f0;
	--brand-text-dark: #333;
	--status-label-default: #697888;
	--status-label-purple: #5b56a2;
	--cm-brand-info: #0e929a;
	--cm-validation-error: #d23f29;
	--cm-border-color: #e3e3e3;
}

.cm-unviewed-text {
	font-weight: bold;
	opacity: 0.87;
}

.no-button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.no-spacing {
	padding: 0;
	margin: 0;
}

.cm-cursor-pointer {
	cursor: pointer;
}

.cm-cursor-help {
	cursor: help;
}

.cm-cursor-pointer:hover {
	cursor: pointer;
}

.cm-drag {
	cursor: move;
}

.cm-drag:hover {
	cursor: move;
}

.cm-no-border-radius {
	border-radius: 0 !important;
}

.cm-mr-10 {
	margin-right: 10px;
}

.cm-ml-10 {
	margin-left: 10px;
}

.cm-mt-10 {
	margin-top: 10px;
}

.cm-mb-10 {
	margin-bottom: 10px;
}

.cm-m-10 {
	margin: 10px;
}

.cm-mt-normal {
	margin-top: var(--spacing-normal);
}

.cm-mt-half {
	margin-top: var(--spacing-half);
}

.cm-mt-double {
	margin-top: var(--spacing-double);
}

.cm-mb-normal {
	margin-bottom: var(--spacing-normal);
}

.cm-mb-half {
	margin-bottom: var(--spacing-half);
}

.cm-mb-double {
	margin-bottom: var(--spacing-double);
}

.cm-ml-normal {
	margin-left: var(--spacing-normal);
}

.cm-ml-half {
	margin-left: var(--spacing-half);
}

.cm-ml-double {
	margin-left: var(--spacing-double);
}

.cm-mr-normal {
	margin-right: var(--spacing-normal);
}

.cm-mr-half {
	margin-right: var(--spacing-half);
}

.cm-mr-double {
	margin-right: var(--spacing-double);
}

.cm-m-normal {
	margin: var(--spacing-normal);
}

.cm-m-half {
	margin: var(--spacing-half);
}

.cm-m-double {
	margin: var(--spacing-double);
}

.cm-p-normal {
	padding: var(--spacing-normal);
}

.cm-pt-normal {
	padding-top: var(--spacing-normal);
}

.cm-pb-normal {
	padding-bottom: var(--spacing-normal);
}

.cm-pl-normal {
	padding-left: var(--spacing-normal);
}

.cm-pr-normal {
	padding-right: var(--spacing-normal);
}

.cm-p-half {
	padding: var(--spacing-half);
}

.cm-pt-half {
	padding-top: var(--spacing-half);
}

.cm-pb-half {
	padding-bottom: var(--spacing-half);
}

.cm-p-double {
	padding: var(--spacing-double);
}

.cm-pt-double {
	padding-top: var(--spacing-double);
}

.cm-pb-double {
	padding-bottom: var(--spacing-double);
}

.cm-pr-double {
	padding-right: var(--spacing-double);
}

.cm-pl-double {
	padding-left: var(--spacing-double);
}

.no-dropdown-arrow::before,
.no-dropdown-arrow::after {
	border: none !important;
	display: inherit !important;
	position: relative !important;
}

.cm-down-arrow {
	/* more triangle */
	position: relative;
	height: 0px;
	width: 0px;
	border: 6px solid;
	border-color: #999 #999 transparent transparent;
	transform: rotate(135deg);
}

.cm-down-arrow:before {
	content: '';
	position: absolute;
	top: 6px;
	right: -6px;
	display: block;
	height: 2px;
	width: 30px;
	background-color: #999;
	transform: rotate(-45deg) translate(0px, 1px);
}

.cm-responsive-widget {
	width: 480px;
	max-width: 480px;
}

@media all and (max-width: 1199px) {
	.cm-responsive-widget {
		width: 600px;
		max-width: 600px;
	}
}

@media all and (max-width: 991px) {
	.cm-responsive-widget {
		width: 480px;
		max-width: 480px;
	}
}

@media all and (max-width: 768px) {
	.cm-responsive-widget {
		width: 320px;
		max-width: 320px;
	}
}

@media all and (max-width: 575px) {
	.cm-responsive-widget {
		width: 100%;
		max-width: 100%;
	}
}

.cm-text-white {
	color: #fff !important;
}

.cm-text-dark {
	color: #333 !important;
}

.cm-strikethrough {
	text-decoration: line-through;
}

.cm-subtitle {
	font-size: 0.85em;
}

.cm-supertitle {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 12px;
}

.cm-hidden {
	display: none;
}

.cm-full-hidden {
	display: none;
}

.cm-mobile-hidden {
	display: inherit;
}

.cm-danger-text {
	color: var(--color-red);
}

.cm-success-text {
	color: var(--color-brand-green);
}

.cm-warning-text {
	color: var(--color-brand-warning-text);
}

.cm-warning-button {
	color: var(--color-brand-warning-text);
	border-color: var(--color-brand-warning-text);
	background-color: transparent;
}

.cm-brand-text {
	color: var(--color-brand-blue);
}

.cm-brand-navy-text {
	color: var(--color-brand-navy);
}

.cm-brand-aqua-text {
	color: var(--color-brand-aqua);
}

.cm-clickable-text {
	color: inherit;
	cursor: pointer;
}

.cm-clickable-text:hover {
	color: var(--color-brand-blue);
	opacity: 0.7;
	transition: all 0.4s;
}

.cm-clickable-text-white {
	color: inherit;
	cursor: pointer;
	border: 1px solid transparent;
	border-radius: 4px;
}

.cm-clickable-text-white:hover {
	color: #fff !important;
	transition: all 0.4s;
	border: 1px solid #fff;
	border-radius: 4px;
}

.cm-app-content {
	margin-top: 100px;
}

.cm-large-header-text {
	font-weight: 300;
	color: var(--color-brand-navy);
	font-size: 38px;
}

.cm-absolute-fill {
	position: absolute;
	width: auto;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.cm-relative-fill {
	position: relative;
	width: auto;
	height: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.cm-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(11rem, 100%), 1fr));
	column-gap: 1rem;
	row-gap: 0;
	overflow: hidden;

	> * {
		transform: translateX(-1px) translateY(1px);
		padding-bottom: 0.5rem;
		padding-top: 0.5rem;

		> * {
			transform: translateX(2px) translateY(-1px);
			border-right: 1px solid #d1d1d1;
		}
	}

	> *:last-child {
		> * {
			border-right: none;
		}
	}
}

@media (max-width: 767px) {
	body {
		position: inherit;
		font-size: 12px;
	}

	.cm-mobile-hidden {
		display: none;
	}

	.cm-full-hidden {
		display: block;
	}
}

@media (max-width: 990px) {
	.cm-mobile-hidden {
		display: none;
	}

	.cm-full-hidden {
		display: block;
	}

	.cm-app-content {
		margin-top: 140px;
	}
}

@media (max-width: 1370px) {
	td > .cm-icon-c-check {
		font-size: 0.688rem;
	}

	.table > tbody > tr > td > p {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

/* ====== reactstrap overrides ====== */
html {
	background-color: var(--main-bg-color);
	scroll-behavior: smooth;
}

a {
	color: var(--link-color);
	font-weight: 400;
}

a:hover {
	color: var(--link-color-hover);
}

.cm-link {
	display: inherit;
	color: var(--link-color);
	font-weight: 400 !important;
	cursor: pointer;
}

.cm-link:hover {
	color: var(--link-color-hover);
	cursor: pointer;
}

.cm-contrast-link {
	color: #fff !important;
}

.cm-contrast-link:hover {
	color: var(--color-brand-medium-blue) !important;
	transition: color 0.3s;
}

hr {
	border-color: var(--divider-line-color);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
	margin: 0;
}

p {
	margin: 5px 0;
}

mark,
.mark {
	padding: 0;
}

.disabled {
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #f5f5f5;
	color: #707070;
	cursor: default;
}

.table > thead > tr > th {
	border: none;
}

.table > tbody > tr > td {
	border: none;
}

.table tbody tr > td {
	border: none;
}

.table > tbody > tr:nth-child(odd) {
	background-color: #fafafa;
}

.table.no-stripe > tbody > tr {
	background-color: transparent;
}

.table > thead > tr > th {
	font-size: 12px;
	font-weight: 700;
	line-height: 1.5em;
	-webkit-font-smoothing: antialiased;
	font-family: 'Outfit', sans-serif;
	padding: 20px 20px 20px 0px;
	margin: 0 20px;
	color: var(--text-color-header);
}

.btn-xs {
	font-size: 10px;
	padding: 4px 12px;
}

.table > thead > tr > th:nth-child(1) {
	padding-left: 40px;
}

.table > tbody > tr > td {
	padding: 16px 16px 16px 0px;
}

.table > tbody > tr > td:nth-child(1) {
	padding-left: 40px;
}

.card > ul {
	list-style-type: none;
	padding: inherit;
	margin: 0;
}

.card > ul > li > *:first-child {
	width: 100%;
}

button.tr-clickable {
	border: inherit;
	background-color: inherit;
	padding: inherit;
}

.tr-clickable:hover,
.table > tbody > tr.tr-clickable:hover {
	cursor: pointer;
	background-color: #eaf3f7;
}

.tr-clickable-highlighted {
	background-color: #eaf3f7 !important;
}

.cm-quickshop-button > button {
	width: 100%;
	color: #f5593d;
	background: #eee;
	border: 0;
	padding: 2px;
}

.text-overflow-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.react-select__menu {
	max-height: 40vh !important;
}

.react-select__menu-list {
	max-height: 40vh !important;
	height: auto !important;
}

.react-select__option {
	padding: 16px 16px 16px 0px;
}

.react-select__option:hover {
	cursor: pointer;
}

.react-select__option:nth-child(odd) {
	background-color: #fafafa !important;
}

.react-select__option--is-focused.react-select__option--is-focused {
	background-color: #eaf3f7 !important;
}

.react-select__option--is-selected.react-select__option--is-selected {
	background-color: #d9e2e6 !important;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: #f5f5f5;
	opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #f5f5f5;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #f5f5f5;
}

input[type='date']::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

.alert > .alert-wrapper > .message > a {
	color: #fff;
	font-weight: bold;
	font-style: italic;
}

.alert > .alert-wrapper > .message > a:hover {
	color: #f0f0f0;
	font-weight: bold;
	font-style: italic;
}

.card a:not(.btn) {
	color: var(--link-color);
	font-weight: 400 !important;
}

.card a:not(.btn):hover {
	color: var(--link-color-hover);
}

.pagination > li > a {
	color: var(--color-brand-medium-blue) !important;
}

.pagination li a:hover {
	cursor: pointer;
}

.pagination > li.active > a,
.pagination > li > a:hover {
	color: #fff !important;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active,
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
	background-color: var(--color-brand-medium-blue) !important;
	border-color: var(--color-brand-medium-blue) !important;
}

.pagination > li > a,
.pagination > li > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border: 2px solid var(--color-brand-medium-blue) !important;
}

.panel-title a {
	font-size: 14px !important;
	color: #444 !important;
	font-family: 'Outfit', sans-serif !important;
	font-weight: 300 !important;
}

.panel-title a:hover {
	color: #f5593d !important;
}

.cm-dropdown-menu {
	box-shadow: 0 2px rgb(17 16 15 / 10%), 0 2px 10px rgb(17 16 15 / 10%);
}

.dropdown-item:disabled {
	color: var(--disabled-grey);
	font-style: italic;
}

.dropdown-item.disabled {
	color: var(--disabled-grey);
	font-style: italic;
}

.dropdown-menu .dropdown-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.textbox-dropdown-menu {
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 400px;
}

.breadcrumb {
	background-color: transparent;
	padding-left: 0;
}

.card {
	box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

.card:not(.card-plain):hover {
	box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
	-webkit-transform: none;
}

.nav-tabs-navigation {
	margin: 0;
	border-bottom: none;
}

.nav-tabs-wrapper {
	display: block;
}

.nav-tabs-wrapper:hover {
	cursor: pointer;
}

.nav-tabs .nav-item .nav-link.active {
	color: var(--color-brand-blue);
}

.input-group > .input-group-prepend > .input-group-text {
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

.input-group .form-control:last-child {
	border-left: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

.form-control + .form-control-feedback {
	position: inherit;
	color: #f5593d;
}

.form-control-feedback {
	position: inherit;
	color: #f5593d;
}

.form-control {
	padding-right: 12px !important;
}

.list-group-item {
	z-index: 10;
}

.list-group-item-first-child-override {
	border-top: 0;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
}

@media (min-width: 1400px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1340px;
	}
}

@media (min-width: 1600px) {
	.col-xxl-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
}

.react-grid-Container {
	width: auto !important;
}

.btn.btn-section {
	color: #f7765f;
}

.btn.btn-section:hover {
	color: #d6654d !important;
}

.react-multiple-carousel__arrow {
	z-index: 5;
}

.cm-bill-duplication-review-container {
	height: 100%;
	position: initial;
	padding-bottom: -1px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.cm-no-overflow {
	overflow-x: hidden !important;
	overflow-y: hidden !important;
}

/* ==== NumberIncrementInput ====== */
.cm-number-increment-input {
	min-width: 100px;
}

.cm-number-increment-input-button {
	user-select: none;
	cursor: pointer;
}

.cm-number-increment-input-input {
	padding-left: 0 !important;
	padding-right: 0 !important;
	text-align: center;
}

.cm-number-increment-input-hover-label {
	position: absolute;
	font-size: 10px;
	left: 0;
	bottom: -20px;
}

/* ===== TableSearch ======= */
.cm-table-search-container {
	align-items: center;
	justify-content: left;
	padding-left: var(--spacing-normal);
	padding-right: var(--spacing-normal);
}

.cm-table-search-container-focus {
	border-bottom: 1px solid var(--divider-line-color);
}

.cm-table-search {
	display: flex;
	flex: 1;
	max-width: 450px;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: left;
}

.cm-table-search-icon {
	font-size: 1.5em !important;
	font-weight: bold !important;
	color: var(--color-brand-blue);
}

.cm-table-search-input {
	font-size: 1.2em;
	margin-left: var(--spacing-half);
	margin-bottom: 10px;
	margin-top: 10px;
	border: none;
}

.cm-table-search-input:focus {
	border: none;
}

.cm-table-search-input::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: var(--disabled-grey);
	opacity: 1; /* Firefox */
}

.cm-table-search-input:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: var(--disabled-grey);
}

.cm-table-search-input ::-ms-input-placeholder {
	/* Microsoft Edge */
	color: var(--disabled-grey);
}

.cm-sticky-search {
	position: sticky;
	z-index: 2;
	background-color: #fff;
	top: 0;
	left: 0;
	right: 0;
}

.cm-frame {
	position: relative;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
}

.cm-frame-option {
	cursor: pointer;
}

.cm-frame-option:hover {
	border: 1px solid var(--color-gray);
	cursor: pointer;
}

.cm-frame-selected {
	border: 1px solid var(--color-brand-blue);
}

.cm-frame-selected:hover {
	border: 1px solid var(--color-brand-blue);
}

.cm-frame-disabled {
	opacity: 0.8;
	cursor: default;
}

.cm-frame-disabled:hover {
	opacity: 0.8;
	cursor: default;
	border: 1px solid #f0f0f0;
}

.cm-options {
	position: relative;
	margin: 5px;
	padding: 20px;
	border: 1px solid #f0f0f0;
	border-radius: 5px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	min-width: 300px;
	width: 50%;
}

.cm-options-flag {
	position: absolute;
	top: 0;
	margin: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	right: 90px;
	color: #fff;
	font-weight: 500;
	padding: 1px 10px;
	background-color: var(--color-brand-blue);
	font-size: 0.9em;
}

.cm-options:hover {
	border: 1px solid var(--color-brand-blue);
}

#react-select-portal > div {
	z-index: 1001;
}

#hubspot-messages-iframe-container {
	z-index: 999 !important;
}

/* ===== LoadingView ====== */
.cm-loading-view-center {
	position: absolute;
	top: 20px;
	left: 50%;
	margin-left: -20px;
}

/* ========== page ============ */
.cm-page {
	padding: 20px;
}

.cm-page-content {
	margin-top: 20px;
}

/* =========== LAYOUT ============ */
.cm-scroll-layout {
	white-space-collapse: collapse;
	text-wrap: balance;
	padding: 0;
	margin: 0;
	height: 100%;
	overflow: scroll;
}

.cm-scroll-layout::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	background: transparent;
}

.cm-sticky-side-layout {
	display: flex;
}

.cm-sticky-side-layout .left {
	position: fixed;
	width: 340px;
	margin-right: var(--spacing-normal);
	height: calc(100vh - 150px);
	overflow: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.cm-sticky-side-layout .left::-webkit-scrollbar {
	display: none;
}

.cm-sticky-side-layout .right {
	margin-left: 360px;
	flex-basis: 100%;
	height: 100vh;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.cm-sticky-side-layout .right::-webkit-scrollbar {
	display: none;
}

.cm-sticky-side-layout-filter-button {
	display: none;
}

@media all and (max-width: 1199px) {
	.cm-sticky-side-layout .left {
		display: none;
	}

	.cm-sticky-side-layout .right {
		margin-left: 0px;
	}

	.cm-sticky-side-layout-filter-button {
		display: inherit;
	}
}

/* =========== FORMS ============= */

.cm-form-check {
	position: relative;
	display: block;
}

.cm-form-check-label {
	display: inline-block;
	position: relative;
	padding-left: 25px;
	padding-right: 10px;
	line-height: 20px;
	margin-bottom: 4px;
}

.cm-form-check-label > input[type='checkbox'] {
	align-self: baseline;
	margin-top: 4px;
}

#legacy-root .cm-legacy-checkbox {
	border-radius: 4px;
}

#legacy-root .cm-legacy-checkbox:checked {
	background-color: var(--color-brand-blue);
}

.cm-value-type-dropdowns {
	margin: 5px;
}

.cm-has-danger {
	border: 1px solid var(--cm-validation-error) !important;
	border-radius: 6px;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box; /* Firefox, other Gecko */
	box-sizing: border-box;
}

.cm-has-danger > input,
.cm-has-danger > .input-group-prepend > .input-group-text,
.cm-has-danger > .input-group-append > .input-group-text {
	border: 0;
}

.cm-has-danger > .form-control:last-child {
	border-right: 0;
}

/* ========= cards ========== */
.cm-card-content {
	padding: 20px;
	background-color: #fff;
}

.cm-card-content-inactive {
	padding: 20px;
	background-color: #f0f0f0 !important;
}

.cm-card-header-tab {
	font-weight: 600;
	white-space: nowrap;
	color: var(--text-color-header);
	padding: var(--spacing-normal) var(--spacing-double);
}

.cm-card-header-tab:hover {
	cursor: pointer;
	color: var(--text-color-header-hover);
}

.cm-card-header-tab-active {
	color: var(--primary);
	border-bottom: 2px solid var(--primary);
}

.cm-card-header-tab-active:hover {
	color: var(--color-brand-blue);
}

.cm-csm-agent-div {
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.cm-csm-agent-data {
	width: 200px;
	text-align: start;
}

.cm-csm-agent-phone-data {
	width: 175px;
	text-align: center;
}

@media (max-width: 1340px) {
	.cm-csm-agent-div {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.cm-csm-agent-phone-data {
		width: 200px;
		text-align: start;
	}
}

/* ======== horizontalslide ============== */
.cm-horizontal-slide-wrapper {
	display: flex;
	flex-direction: row;
}

.cm-horizontal-slide {
	max-width: 100%;
	flex: 1;
	white-space: nowrap;
	word-wrap: break-word;
	margin-right: -5000px;

	transition: margin-right 0.5s ease-in-out;
}

.cm-horizontal-slide-is-open.cm-horizontal-slide {
	max-width: 100%;
	margin-right: 0;
	flex: 1;
}

@media (max-width: 767px) {
	.cm-horizontal-slide-wrapper {
		flex-direction: column;
	}

	.cm-horizontal-slide {
		max-width: 100%;
		max-height: 100%;
		white-space: nowrap;
		text-overflow: clip;
		word-wrap: break-word;
		display: none;
	}

	.cm-horizontal-slide-is-open.cm-horizontal-slide {
		max-height: 100%;
		max-width: 100%;
		display: block;
	}
}

/* ============= Image Picker ================= */
.cm-image-picker {
	position: relative;
}

.cm-image-picker img,
.cm-image-picker-img {
	width: 100%;
	overflow: hidden;
	flex: 1;
	object-fit: cover;
	border-radius: 0;
}

.cm-image-picker-overlay {
	position: absolute;
	z-index: 100;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0;
	cursor: pointer;
	transition: all 0.5s;
}

.cm-image-picker-overlay:hover {
	opacity: 1;
	transition: all 0.5s;
}

.cm-overlay-button {
	width: 100%;
	position: absolute;
	bottom: 20px;
	text-align: center;
}

.cm-overlay-button-small {
	width: 100%;
	position: absolute;
	top: 35px;
	text-align: center;
}

.cm-image-picker-round {
	border-radius: 50% !important;
}

/* ======== List Item ========= */
.listItemContainer {
	padding: 8px 0px;
}

.listItemContainer:hover {
	cursor: pointer;
}

.listItemContainer:hover .listItem {
	color: var(--link-color);
}

.listItem {
	margin: 0;
	font-weight: 400;
	color: #666;
}

.cm-list-item-active {
	color: var(--link-color);
}

/* ======== Buttons ========= */
#legacy-root .cm-button-transparent {
	border-color: rgba(255, 255, 255, 0);
	background-color: rgba(255, 255, 255, 0);
	color: inherit;
	padding: 2px;
	height: 30px;
	width: 30px;
	max-width: 30px;
	min-width: 30px;
}

#legacy-root .cm-button-transparent.disabled {
	border-color: rgba(255, 255, 255, 0);
	background-color: rgba(255, 255, 255, 0);
	color: inherit;
	padding: 2px;
	height: 30px;
	width: 30px;
	max-width: 30px;
	min-width: 30px;
	opacity: 0.5;
	cursor: not-allowed;
}

#legacy-root .cm-button-transparent:hover {
	border-color: rgba(255, 255, 255, 0);
	background-color: rgba(255, 255, 255, 0);
	color: var(--color-brand-blue);
	opacity: 0.5;
	transition: all 0.3s;
}

#legacy-root .cm-button-transparent:focus {
	border-color: rgba(255, 255, 255, 0);
	background-color: rgba(255, 255, 255, 0);
	color: inherit;
	opacity: 1;
	transition: all 0.3s;
}

#legacy-root .btn-danger.disabled {
	background-color: #eee;
}

#legacy-root .btn-link.disabled {
	background-color: transparent;
	cursor: not-allowed;
}

#legacy-root .cm-clickable-title {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
}

#legacy-root .cm-clickable-title:hover {
	color: var(--color-brand-blue);
	transition: all 0.2s;
}

#legacy-root .cm-button-navy {
	border-color: var(--color-brand-navy) !important;
	color: var(--color-brand-navy) !important;
}

#legacy-root .cm-button-navy:hover {
	background-color: var(--color-brand-navy) !important;
	border-color: var(--color-brand-navy) !important;
	color: #fff !important;
}

#legacy-root .cm-button-navy:focus .cm-button-navy:active {
	background-color: var(--color-brand-navy) !important;
	border-color: var(--color-brand-navy) !important;
	color: #fff !important;
}

#legacy-root .cm-button-navy:active {
	background-color: var(--color-brand-navy) !important;
	border-color: var(--color-brand-navy) !important;
	color: #fff !important;
}

#legacy-root .cm-button-medium-blue {
	border-color: var(--color-brand-medium-blue) !important;
	color: var(--color-brand-medium-blue) !important;
}

#legacy-root .cm-button-medium-blue:hover {
	background-color: var(--color-brand-medium-blue) !important;
	border-color: var(--color-brand-medium-blue) !important;
	color: #fff !important;
}

#legacy-root .cm-button-medium-blue:focus .cm-button-medium-blue:active {
	background-color: var(--color-brand-medium-blue) !important;
	border-color: var(--color-brand-medium-blue) !important;
	color: #fff !important;
}

#legacy-root .cm-button-medium-blue:active {
	background-color: var(--color-brand-medium-blue) !important;
	border-color: var(--color-brand-medium-blue) !important;
	color: #fff !important;
}

#legacy-root .cm-button-medium-default {
	border-width: 1px;
	border-color: var(--color-gray) !important;
	color: var(--color-gray) !important;
}

#legacy-root .cm-button-medium-default:hover {
	background-color: var(--color-brand-medium-blue) !important;
	border-color: var(--color-brand-medium-blue) !important;
	color: #fff !important;
}

#legacy-root .cm-button-medium-default:focus .cm-button-medium-default:active {
	background-color: var(--color-brand-medium-blue) !important;
	border-color: var(--color-brand-medium-blue) !important;
	color: #fff !important;
}

#legacy-root .cm-button-medium-default:active {
	background-color: var(--color-brand-medium-blue) !important;
	border-color: var(--color-brand-medium-blue) !important;
	color: #fff !important;
}

#legacy-root .cm-button-small {
	font-size: 11px;
	padding: 3px 8px;
}

.cm-legacy-btn-primary {
	background-color: #0071b8 !important;
	color: #f0f0f0 !important;
}

.cm-legacy-btn-danger {
	background-color: var(--color-red) !important;
	color: #f0f0f0 !important;
}

/* ======== Text ========= */
.cm-legacy-text-input {
	border: 1px solid #ddd !important;
	border-radius: 4px;
}

.cm-legacy-text-input:focus {
	border: 1px solid #ddd !important;
	border-radius: 4px;
}

.react-select__input input:focus {
	border: none !important;
	box-shadow: none;
	border-radius: 4px;
}

.cm-select__input input:focus {
	border: none !important;
	box-shadow: none;
	border-radius: 4px;
}

.cm-label-info {
	color: var(--color-brand-blue);
	font-style: italic;
}

.cm-subtext-italic {
	color: #999;
	font-style: italic;
}

.cm-flag {
	position: absolute;
	right: 0;
	top: 0;
	border-bottom-left-radius: 100px;
	border-top-left-radius: 5px;
	border-top-right-radius: 28px;
	padding: 0 10px 0 30px;
}

.cm-flag-active {
	background-color: var(--color-brand-blue);
	color: #fff;
}

.cm-flag-success {
	background-color: var(--color-brand-green);
	color: #fff;
}

.cm-flag-danger {
	background-color: var(--color-red);
	color: #fff;
}

.cm-flag-inactive {
	background-color: #999;
	color: #fff;
}

.cm-flag-warning {
	background-color: #e2a91a;
	color: #fff;
}

/* ======== Icons + Spinning ========= */

.cm-icon-1-3-em {
	font-size: 1.3em !important;
}

.cm-icon-xs {
	font-size: 10px !important;
}

.cm-icon-sm {
	font-size: 0.8em !important;
}

.cm-icon-md {
	font-size: 1.4em !important;
}

.cm-icon-lg {
	font-size: 4.5em !important;
}

.cm-icon-xl {
	font-size: 5.5em !important;
}

i.cm-icon-c-check {
	font-size: 1.2em;
}

.cm-icon-audit {
	margin: 0px;
}

.cm-icon-spin {
	animation: cm-icon-spin 0.7s infinite linear;
}

@keyframes cm-icon-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.cm-icon-rotate-90 {
	animation: cm-icon-rotate-90 0.5s ease;
	transform: rotate(90deg);
	transition: transform 0.5s;
}

@keyframes cm-icon-rotate-90 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(90deg);
	}
}

.cm-icon-rotate-180 {
	animation: cm-icon-rotate-180 0.5s ease;
	transform: rotate(180deg);
	transition: transform 0.5s;
}

@keyframes cm-icon-rotate-180 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(180deg);
	}
}

.cm-icon-rotate-reverse-180 {
	animation: cm-icon-rotate-reverse-180 0.5s ease;
	transform: rotate(180deg);
	transition: transform 0.5s;
}

@keyframes cm-icon-rotate-reverse-180 {
	0% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

/* Button chevron */
.btn > div > div > .cm-icon-rotate {
	transform: rotate(0deg);
	transition: all 0.5s ease;
}

.btn:active > div > div > .cm-icon-rotate {
	transform: rotate(180deg);
	transition: all 0.5s ease;
}

.btn:focus > div > div > .cm-icon-rotate {
	transform: rotate(180deg);
	transition: all 0.5s ease;
}

/* ========  User Profile ========= */
.cm-profile-image-xlarge {
	width: 120px !important;
	height: 120px !important;
}

.cm-profile-image-large {
	width: 80px !important;
	height: 80px !important;
}

.cm-profile-image-medium {
	width: 40px !important;
	height: 40px !important;
}

.cm-profile-image-small {
	width: 28px !important;
	height: 28px !important;
}

.cm-profile-image-xsmall {
	width: 16px !important;
	height: 16px !important;
}

/* =========  Images ========== */
.cm-round-image-small {
	border-radius: 20px;
	width: 40px !important;
	height: 40px !important;
}

.cm-round-image-medium {
	border: 1px solid #ddd;
	padding: 5px;
	background-color: #fff;
	border-radius: 40px;
	width: 80px !important;
	height: 80px !important;
}

.cm-round-image-large {
	border: 1px solid #ddd;
	padding: 10px;
	background-color: #fff;
	border-radius: 60px;
	width: 120px !important;
	height: 120px !important;
}

/* ========= Modals ========== */
.cm-modal-overlay {
	background-color: var(--overlay-gray);
}

.react-responsive-modal-closeButton {
	top: 16px;
}

.cm-modal {
	border-radius: 5px;
	display: grid;
	grid-template-rows: fit-content(50px) 1fr fit-content(70px);
	grid-gap: 0;
	max-height: 90%;
	width: 100%;
	margin-top: 10px;
	padding: 0;
}

.cm-modal-xs {
	max-width: 400px;
}

.cm-modal-sm {
	max-width: 600px;
}

.cm-modal-md {
	max-width: 900px;
}

.cm-modal-1024 {
	max-width: 1024px;
}

.cm-modal-lg {
	max-width: 1200px;
}

.cm-modal-xl {
	max-width: 1600px;
}

.cm-modal-max {
	max-width: 100%;
}

.cm-modal-fullscreen {
	max-width: 100%;
	margin: 0px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.cm-modal-fullscreen-overlay {
	padding: 0;
}

.cm-modal-header {
	width: 100%;
	height: auto;
	padding: 10px 50px 10px 20px;
	border-bottom: 1px solid #f0f0f0;
}

.cm-modal-action-bar {
	width: 100%;
	height: 50px;
	padding: 10px;
	border-bottom: 1px solid #f0f0f0;
}

.cm-modal-content {
	position: relative;
	width: 100%;
	height: 100%;
	padding-bottom: -1px;
	overflow-y: scroll;
	overflow-x: hidden;
	/* overflow: visible; */
}

.cm-modal-content-with-overflow {
	position: relative;
	width: 100%;
	height: 100%;
	padding-bottom: -1px;
	overflow: visible;
}

.cm-modal-footer {
	display: flex;
	width: 100%;
	height: 50px;
	padding: 0 10px;
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	-webkit-box-shadow: 0px -5px 24px -10px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px -5px 24px -10px rgba(0, 0, 0, 0.75);
	box-shadow: 0px -5px 24px -10px rgba(0, 0, 0, 0.75);
	border-radius: 5px;
}

.cm-modal-footer-scroll-done {
	border-top: 1px solid #f0f0f0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-radius: 5px;
}

.cm-modal-absolute-content {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}

.cm-modal-mdl {
	display: flex;
}

.cm-modal-mdl-master {
	position: absolute;
	width: 30%;
	min-width: 400px;
	top: 0;
	bottom: 0;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 1;
	background-color: #fff;
}

.cm-modal-mdl-master-left {
	left: 0;
	border-right: 1px solid #f0f0f0;
}

.cm-modal-mdl-master-right {
	right: 0;
	border-left: 1px solid #f0f0f0;
}

.cm-modal-mdl-detail {
	position: absolute;
	width: 70%;
	max-width: calc(100% - 400px);
	left: 0;
	top: 0;
	bottom: 0;
	background-color: var(--color-light-grey);
	overflow-y: scroll;
	overflow-x: hidden;
}

.cm-modal-mdl-detail-left {
	right: 0;
	left: 40%;
}

.cm-modal-mdl-detail-right {
	left: 0;
}

@media (max-width: 990px) {
	.cm-modal-mdl-master {
		position: absolute;
		width: 60%;
		min-width: 450px;
		top: 0;
		bottom: 0;
		right: 0;
		overflow-y: scroll;
		overflow-x: hidden;
		z-index: 2;
		background-color: #fff;
		transition: all 0.3s;
	}

	.cm-modal-mdl-master-collapsed {
		left: calc(100% - 60px);
		transition: all 0.3s;
	}

	.cm-modal-mdl-master-content-collapsed {
		visibility: hidden;
	}

	.cm-modal-mdl-detail {
		width: calc(100% - 60px);
		max-width: calc(100% - 60px);
		position: absolute;
		top: 0;
		left: 0;
		right: 60px;
		bottom: 0;
		background-color: var(--color-light-grey);
		overflow-y: scroll;
		overflow-x: hidden;
	}
}

/* ======== Banners ========= */

.cm-clickable-banner {
	height: 140px;
	width: 380px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--color-brand-blue);
	border-radius: 2px;
	cursor: pointer;
}

.cm-clickable-banner:hover {
	box-shadow: 3px 3px 6px #dfdfdf;
	transition: all 0.3s;
}

.cm-mini-carousel-item {
	min-height: 140px;
	width: auto;
	border-radius: 2px;
}

.cm-mini-banner-title {
	font-size: 0.9em !important;
}

.cm-mini-banner-subtitle {
	font-size: 7px !important;
}

.cm-mini-banner-button {
	font-size: 6px;
	padding: 0.1rem 6px;
}

.cm-multi-carousel-item-override {
	width: auto !important;
}

.cm-multi-carousel-track-override {
	width: auto !important;
}

.cm-multi-carousel-vendor-item {
	width: 110px !important;
}

.cm-multi-carousel-category-item {
	min-height: 70px !important;
	background-color: #606060;
}

.cm-multi-carousel-product-item {
	width: 250px !important;
}

/* =========  CART ========= */

.cm-cart-preview-wrapper > div.popover {
	margin-right: 8px;
	left: -5px !important;
	max-height: 85%;
	overflow: auto;
}

.cm-cart-preview-details {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 12px;
}

.cm-cart-preview-item {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 8px 12px;
	position: relative;
}

.cm-cart-preview-item a {
	color: inherit;
}

.cm-cart-preview-item a:hover {
	color: var(--color-brand-blue);
	transition: color 0.3s;
}

.cm-cart-preview-item-deleting {
	opacity: 0.5;
	transition: opacity 0.2s;
}

.cm-cart-preview-details-text {
	font-size: 12px;
	text-align: left !important;
}

.cm-cart-preview-details-text span {
	font-size: 12px;
	text-align: left !important;
}

.cm-cart-preview-actions .btn {
	border-radius: 0;
}

.cm-cart-item-opacity-dim {
	opacity: 0.5;
	transition: opacity 0.3s;
}

.cm-cart-item-success-icon {
	font-size: 18px !important;
	margin-top: 10px;
}

@media (min-width: 1200px) {
	.cm-cart-subtotal-fixed {
		position: fixed;
	}
}

@media (max-width: 1200px) {
	.cm-cart-subtotal-fixed {
		position: inherit;
		width: 100%;
	}
}

@media (max-width: 992px) {
	.cm-cart-subtotal-fixed {
		position: inherit;
		width: 100%;
	}
}

@media (min-width: 993px) {
	.cm-cart-item-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
}

@media (max-width: 992px) {
	.cm-cart-item-image {
		width: 80px;
		height: 80px;
		object-fit: contain;
	}
}

@media (max-width: 575px) {
	.cm-cart-price {
		font-size: 12px !important;
	}
}

.cm-cart-checkout-button > button {
	width: 100%;
}

/* =========  QUICKSHOP ========= */

.cm-quickshop-button {
	transition: all 0.5s;
	position: absolute;
	top: 164px;
	margin: auto;
	width: 100%;
	color: #f5593d;
	background: #eee;
	padding: 2px;
}

.cm-quickshop-button:hover {
	background: #e5e5e5;
}

.cm-quickshop-button p {
	font-weight: 500;
}

@media (min-width: 992px) {
	.cm-quickshop-modal {
		max-width: 60em !important;
	}
}

.cm-quickshop-details .card-header {
	background: #fff;
}

.cm-quickshop-modal-image {
	object-fit: cover;
	width: 100%;
}

/* =========  CATALOG ========= */
.cm-catalog-vendor-image-container {
	margin: 5px;
	display: inline-block;
}

.cm-catalog-vendor-image {
	width: 50px;
	height: 50px !important;
	border: 1px solid #d0d0d0;
	border-radius: 25px !important;
	padding: 5px;
}

.cm-catalog-vendor-item-notes {
	padding: 20px;
	background-color: #fff;
	border-radius: 10px;
}

/* ========  FILTER LIST ========= */
.cm-filterlist {
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
}

.cm-filterlist-sidebar {
	min-width: 300px;
	max-width: 350px;
	width: 25%;
	border-right: 1px solid #f0f0f0;
}

.cm-filterlist-content {
	flex: 1;
	min-height: 100%;
	overflow: hidden;
}

/* =========  MARKETPLACE ========= */

@media (max-width: 768px) {
	.cm-marketplace-list-product-title {
		font-size: 1.15em !important;
	}

	.cm-marketplace-list-product-sku {
		font-size: 12px !important;
	}
}

.cm-quantity-dropdown div > div > div.react-select__menu {
	z-index: 9999 !important;
}

.cm-quantity-dropdown
	div
	> div
	> div.react-select__menu
	> div.react-select__menu_list {
	z-index: 9999 !important;
}

.cm-reset-stacking-context-override {
	opacity: 0.999 !important;
}

.cm-override-opacity {
	opacity: 1 !important;
}

/* =========  STORE - PRODUCT DETAILS ========= */

.cm-store-product-image {
	width: 100%;
}

.cm-store-product-title {
	font-size: 2em;
}

.cm-store-product-details .card-header {
	background-color: transparent;
}

/* =========  GLOBAL SEARCH ========= */

.cm-navbar-search-list {
	max-height: 600px;
	overflow-y: auto;
	list-style-type: none;
	padding: 0px;
	margin: 0px;
}

li > button.cm-search-result-item {
	width: 100%;
	border: none;
	background-color: #fff;
}

.cm-search-result-item:hover {
	cursor: pointer;
	background-color: #f0f0f0;
}

@media (max-width: 768px) {
	.cm-navbar-search-list {
		max-height: 400px;
	}
}

/* ========== ORDER DETAILS =========== */
.cm-order-details-suborder-section {
	height: 60px;
	padding: 10px 20px 10px 20px;
}

.cm-order-details-suborder-section:hover {
	color: var(--color-brand-blue);
	transition: color 0.4s;
}

.cm-order-comment-box {
	height: 30px;
	border: 0;
	display: flex;
	width: 100%;
}

.cm-order-comment-input-container {
	width: 75%;
}

/* ========== Progress Bar =========== */

.cm-progress-bar-extra-danger {
	background-color: #d06754;
}

.cm-progress-bar-grey {
	background-color: #eceef0;
}

.cm-progress-bar-success {
	background-color: #2a8d4b;
}

.cm-progress-bar-danger {
	background-color: #d23f29;
}

.cm-progress-bar-warning {
	background-color: #e7ae1b;
}

.cm-progress-bar-purple {
	background-color: #d38aeb;
}

/* needed to override Reactstrap height enforcement of 8px */
.progress {
	height: 20px;
}

/* ======= topnavbar ========*/
.cm-topnav {
	box-shadow: none;
	padding: 0;
	background-color: var(--main-bg-color);
	min-height: 70px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
	transition: all 0.3s;
}

.cm-topnav-marketplace {
	min-height: 90px;
}

.cm-topnav-side-open {
	left: 240px;
	transition: all 0.3s;
}

.cm-topnav-logo {
	width: 200px;
	transition: all 0.3s;
}

.cm-topnav-logo-side-open {
	overflow: hidden;
	width: 0;
	transition: all 0.3s;
}

.cm-topnav-user-section {
	margin: 5px 0;
}

.cm-topnav-user-section:hover {
	color: #aaa;
}

.cm-topnav-user-section:hover div {
	color: #aaa;
}

.cm-topnav-user-name-label {
	text-align: right;
	margin-right: 10px;
}

.cm-topnav-user-name-label div {
	font-weight: 400;
	color: #999;
}

.cm-topnav-mobile-search {
	display: none;
	box-shadow: none;
	padding-left: var(--spacing-normal);
	padding-right: var(--spacing-normal);
	padding-top: var(--spacing-half);
	background-color: var(--main-bg-color);
	height: 60px;
	position: fixed;
	top: 60px;
	left: 0;
	right: 0;
	z-index: 20;
	transition: all 0.3s;
}

@media (max-width: 990px) {
	.cm-topnav-mobile-search {
		display: inherit;
	}

	.cm-topnav-side-open {
		left: 0px;
		transition: all 0.3s;
	}
}

.cm-topnav-secondary {
	border-bottom: 1px solid #d0d0d0;
	border-top: 1px solid #d0d0d0;
	padding: 10px 0px;
	margin: 0px;
	align-content: center;
	text-align: center;
}

.cm-topnav-secondary-link {
	color: #0c384f;
}

.cm-topnav-secondary-link:hover {
	color: var(--primary);
	cursor: pointer;
}

/* ========= SUPER SLIDEY BAR ========= */

.cm-sidenav {
	min-width: 0;
	background-color: var(--main-bg-color);
	height: fit-content;
}

.cm-super-slidey-content {
	position: absolute;
	left: 0px;
	bottom: 0px;
	right: 0px;
	top: 0px;
	background-color: #f9f9fa;
	z-index: 1;
	transition: all 0.3s;
}

.cm-super-slidey-bar {
	position: fixed;
	left: 0px;
	bottom: 0px;
	width: 240px;
	top: 0px;
	overflow: hidden;
	background-color: #f9f9fa;
	z-index: 10;
	transition: all 0.3s;
}

.cm-super-slidey-bar-children {
	position: relative;
	width: 256px;
	height: 100%;
	overflow-y: scroll;
}

.cm-super-slidey-bar-visible {
	left: 0px;
	transition: all 0.3s;
}

.cm-super-slidey-bar-hidden {
	left: -240px;
	transition: all 0.3s;
}

.cm-super-slidey-bar-content-expanded {
	left: 240px;
	transition: all 0.3s;
}

.cm-super-slidey-barsmall {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 415px;
	top: 0px;
	overflow: hidden;
	background-color: #fff;
	z-index: 10;
	transition: all 0.3s;
}

.cm-super-slidey-bar-childrensmall {
	position: relative;
	width: 414px;
	height: 100%;
	overflow: visible;
}

.cm-super-slidey-bar-visiblesmall {
	right: 0px;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
	overflow: visible;
}

.cm-super-slidey-bar-hiddensmall {
	width: 60px;
	min-width: 60px;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
}

.cm-super-slidey-bar-right-sidepanel-sm {
	position: fixed;
	right: 0;
	bottom: 0px;
	width: 400px;
	top: 0px;
	overflow: hidden;
	background-color: #fff;
	z-index: 1000;
	transition: all 0.3s;
}

.cm-super-slidey-bar-children-right-sidepanel-sm {
	position: relative;
	margin-right: 0;
	width: 414px;
	height: 100%;
	overflow-y: scroll;
}

.cm-super-slidey-bar-visible-right-sidepanel-sm {
	right: 0;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
	overflow-y: scroll;
}

.cm-super-slidey-bar-hidden-right-sidepanel-sm {
	width: 0;
	border-left: none;
	transition: all 0.3s;
}

.cm-super-slidey-bar-right-sidepanel-md {
	position: fixed;
	right: 0;
	bottom: 0px;
	width: 40%;
	top: 0px;
	overflow: hidden;
	background-color: #fff;
	z-index: 1000;
	transition: all 0.3s;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.cm-super-slidey-bar-right-sidepanel-md::-webkit-scrollbar {
	display: none;
}

.cm-super-slidey-bar-children-right-sidepanel-md {
	position: relative;
	margin-right: 0;
	height: 100%;
	overflow-y: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.cm-super-slidey-bar-children-right-sidepanel-md::-webkit-scrollbar {
	display: none;
}

.cm-super-slidey-bar-visible-right-sidepanel-md {
	right: 0;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
	overflow-y: scroll;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.cm-super-slidey-bar-visible-right-sidepanel-md::-webkit-scrollbar {
	display: none;
}

.cm-super-slidey-bar-hidden-right-sidepanel-md {
	width: 0;
	border-left: none;
	transition: all 0.3s;
}

.cm-super-slidey-bar-right-sidepanel-lg {
	position: fixed;
	right: 0;
	bottom: 0px;
	width: 60%;
	top: 0px;
	overflow: hidden;
	background-color: #fff;
	z-index: 1000;
	transition: all 0.3s;
}

.cm-super-slidey-bar-children-right-sidepanel-lg {
	position: relative;
	margin-right: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
}

.cm-super-slidey-bar-visible-right-sidepanel-lg {
	right: 0;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
	overflow-y: scroll;
}

.cm-super-slidey-bar-hidden-right-sidepanel-lg {
	width: 0;
	border-left: none;
	transition: all 0.3s;
}

.vendor-slidey-bar-content {
	padding-right: 5px;
}

.vendor-slidey-bar-content p {
	font-size: 13px;
}

.vendor-slidey-bar-content .danger-icon {
	margin-top: 5px;
}

.cm-super-slidey-barresponsive {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 45%;
	min-width: 415px;
	top: 0px;
	overflow: hidden;
	background-color: #fff;
	z-index: 10;
	transition: all 0.3s;
}

.cm-super-slidey-bar-childreresponsive {
	position: relative;
	padding-right: 1px;
	min-width: 414px;
	height: 100%;
	overflow: visible;
}

.cm-super-slidey-bar-visibleresponsive {
	right: 0px;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
	overflow: visible;
}

.cm-super-slidey-bar-hiddenresponsive {
	width: 60px;
	min-width: 60px;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
}

.cm-super-slidey-bar-modal-subpage {
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 100%;
	top: 0px;
	overflow: hidden;
	background-color: #fff;
	z-index: 10;
	transition: all 0.3s;
}

.cm-super-slidey-bar-children-modal-subpage {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: visible;
}

.cm-super-slidey-bar-visible-modal-subpage {
	right: 0px;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
	overflow: visible;
}

.cm-super-slidey-bar-hidden-modal-subpage {
	width: 0px;
	min-width: 0px;
	border-left: 1px solid var(--divider-line-color);
	transition: all 0.3s;
}

.cm-suborder-sidebar-button {
	width: 60px;
	position: relative;
}

.cm-suborder-sidebar-button-icon {
	font-size: 1.5em !important;
	margin: var(--spacing-normal);
}

.cm-suborder-sidebar-button-icon-notifier {
	position: absolute;
	font-size: 0.7em !important;
	right: 5px;
	top: 5px;
}

.cm-suborder-sidebar-button:hover {
	cursor: pointer;
	color: var(--color-brand-blue);
}

.cm-suborder-sidebar-button-active {
	color: var(--color-brand-blue);
	border-right: 1px solid var(--color-brand-blue);
}

.cm-sidenav-close-button {
	position: fixed;
	left: 220px;
	top: 15px;
	z-index: 20;
	transition: all 0.3s;
}

.cm-sidenav-close-button-hidden {
	position: fixed;
	left: 15px;
	top: 15px;
	z-index: 20;
	transition: all 0.3s;
}

.cm-right-sidebar-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	opacity: 0.99;
	filter: alpha(opacity=99);
	background-color: #00000066;

	pointer-events: none;
	transition: all 1s;
}

@media (max-width: 990px) {
	.cm-super-slidey-bar-content-expanded {
		left: 0px;
		transition: all 0.3s;
	}

	.cm-mobile-overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9;
		opacity: 0.99;
		filter: alpha(opacity=99);
		background-color: #00000066;
		-webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px);
		pointer-events: none;
		transition: all 1s;
	}
}

/* ========= Request Popover ========= */
.cm-select-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
}

.cm-popover-container {
	position: relative;
	overflow-y: visible;
	height: 100vh;
	background-color: #f9f9fa;
}

/* ========= Request Popover ========= */
.popover-button {
	background-color: transparent;
	border: 0;
	width: 100%;
	margin: 0;
	padding: 0;
}

.popover-button:focus {
	border: 0;
	outline: none;
}

.popover-panel {
	z-index: 100;
}

/* ========= Onboarding Checklist ============ */
.cm-onboarding-checklist {
	margin: 20px;
}

.cm-onboarding-location-row {
	cursor: pointer;
	position: relative;
}

.cm-onboarding-location-row:hover {
	opacity: 0.7;
	transition: opacity 0.3s;
}

.cm-onboarding-location-row-error {
	font-size: 17px !important;
	position: absolute;
	bottom: 4px;
}

.cm-onboarding-icon {
}

.cm-onboarding-icon-success {
	color: var(--color-brand-green) !important;
	transition: color 0.5s;
}

.cm-onboarding-icon-incomplete {
	color: var(--disabled-grey);
	transition: color 0.5s;
}

.cm-onboarding-billing-address-tooltip {
	top: -41px !important;
	left: 180px !important;
}

/* ========= Favorite Icon ============ */

.cm-favorite-icon {
	position: absolute;
	z-index: 19;
	height: 45px;
	width: 45px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.cm-favorite-icon-image {
	top: 5px;
	left: 5px;
	font-size: 25px;
}

.cm-favorite-icon-table {
	font-size: 20px;
	margin-top: -6px;
}

.cm-icon-hidden {
	opacity: 0;
	transition: opacity 0.3s;
	color: inherit;
	pointer-events: none;
}

.cm-icon-visible {
	opacity: 1;
	color: #f5593d;
	transition: all 0.3s;
}

.cm-icon-flutter {
	transition: all 0.3s ease-in-out;
}

.cm-icon-flutter:hover {
	transform: scale(1.1);
}

/* ========= Popover Sizes ========= */

.cm-small-width-popover {
	min-width: 400px !important;
}

.cm-xs-width-popover {
	min-width: 300px !important;
}

.cm-popover-restricted-height {
	max-height: 400px !important;
	overflow-y: scroll;
}

.cm-datepicker-popover {
	min-width: 890px;
}

.cm-datepicker-popover-medium {
	min-width: 590px;
}

.cm-date-picker-popover-small {
	min-width: 332px;
}

/* ========= Filters ========= */

/* popover */

.cm-popover-filter-header {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 2;
	background-color: #0089dd;
	color: #fff;
	border-bottom: 1px solid #eee;
	padding: 8px;
}

.cm-filter-pill {
	background-color: var(--color-brand-blue) !important;
	margin-bottom: 10px;
	padding: 9px 13px;
	font-size: 11px;
}

.cm-filter-pill-close-button:hover {
	color: rgba(255, 255, 255, 0.8);
	transition: all 0.3s;
}

/* ============= animations ========== */

.scale-up-center {
	-webkit-animation: scale-up-center 0.5s
		cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s 1 both;
	animation: scale-up-center 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s 1
		both;
	transform: scale(1);
	animation-play-state: paused;
}

.scale-up-center:hover {
	animation-play-state: running;
	transition: 1s;
}

@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.03);
		transform: scale(1.03);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	50% {
		-webkit-transform: scale(1.03);
		transform: scale(1.03);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

/* ============= Radios ========== */

.cm-block-radio-label {
	background-color: transparent;
	color: inherit;
	border-radius: 5px;
	line-height: 35px;
	padding-right: 26px;
	transition: all 0.3s;
}

.cm-block-radio-label-active {
	background-color: var(--color-brand-blue);
	color: white;
	border-radius: 5px;
	line-height: 35px;
	padding-right: 26px;
	transition: all 0.3s;
}

.cm-radio-bordered {
	background-color: transparent;
	color: inherit;
	border-radius: 5px;
	line-height: 35px;
	padding-right: 26px;
	transition: all 0.3s;
	border: 1px solid rgba(0, 0, 0, 0.125);
	width: 100%;
	margin-bottom: 20px;
}

.cm-radio-bordered-active {
	border: 1px solid var(--color-brand-blue);
	transition: all 0.3s;
	border-radius: 5px;
	line-height: 35px;
	padding-right: 26px;
	width: 100%;
	margin-bottom: 20px;
}

.cm-radio-bordered-fill-active {
	border: 1px solid #0071b8;
	background-color: #e1f3ff;
	transition: all 0.3s;
	border-radius: 5px;
	line-height: 35px;
	padding-right: 26px;
	width: 100%;
	margin-bottom: 20px;
}

/* ============= Slide Content In ========== */

.cm-slide-hidden {
	height: 100px;
	width: 0;
	transition: all 1s;
	background-color: #0c384f;
	color: #fff;
}

.cm-slide-visible {
	height: 100px;
	width: 240px;
	transition: all 1s;
	background-color: #0c384f;
	color: #fff;
}

/* ============= Position Relative ========== */

.cm-position-relative {
	position: relative;
}

/* ============= Vendor Organization Onboarding ========== */

.cm-dark-collapse {
	background-color: var(--color-brand-navy);
	color: #fff;
	padding: 20px;
}

/* ============= date-picker =================== */
.cm-date-picker {
	max-width: 890px;
}

/* ============= Marketplace Grid List Toggle ========== */

.nav-pills .nav-item .nav-link.active {
	border: 1px solid var(--color-brand-medium-blue) !important;
	background-color: var(--color-brand-medium-blue) !important;
	color: #fff !important;
}

.nav-pills .nav-item .nav-link {
	border: 1px solid var(--color-brand-medium-blue) !important;
	color: var(--color-brand-medium-blue) !important;
}

/* ============= Badges ========== */

.cm-badge-medium-blue {
	background-color: var(--color-brand-medium-blue) !important;
}

.cm-badge-medium-gray {
	background-color: #c9c9c9 !important;
	color: var(--overlay-gray) !important;
}

/* ============= Hover Input ========== */

.cm-hover-input-label {
	font-size: 12px;
	margin: 0;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 1.5em;
}

.cm-hover-input {
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
	font-size: 0.85rem;
	padding-right: 0 !important;
	padding: 7px 12px;
	height: 40px;
	margin-top: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	font-weight: 400;
	cursor: text;
}

.cm-hover-input:hover {
	border: 1px solid #ddd;
	border-radius: 4px;
	color: #66615b;
	transition: all 0.3s;
}

.cm-hover-input-textarea {
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
	font-size: 0.85rem;
	padding-right: 0 !important;
	padding: 10px 18px;
	/*height: 40px;*/
	line-height: 1.5;
	margin-top: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	font-weight: 400;
	cursor: text;
}

.cm-hover-input-textarea:hover {
	border: 1px solid #ddd;
	border-radius: 4px;
	color: #66615b;
	transition: all 0.3s;
}

.cm-hover-input-disabled {
	cursor: not-allowed;
}

.cm-hover-input:hover
	> .cm-hover-input-prepend
	> .input-group-prepend
	> .input-group-text {
	border: 1px solid #ddd;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	transition: all 0.3s;
}

.cm-hover-textarea {
	border: 1px solid transparent;
	border-radius: 4px;
}

.cm-hover-textarea:hover {
	border: 1px solid #ddd;
	border-radius: 4px;
	transition: all 0.3s;
}

.cm-hover-input-prepend > .input-group-prepend > .input-group-text {
	margin-left: -13px;
	margin-right: 13px;
	border: 1px solid transparent;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	padding: 13px 12px 12px 12px;
}

.cm-select-has-danger > .react-select__control {
	border: 1px solid var(--cm-validation-error);
	color: #f5593d;
}

.cm-standard-input-hover > div > input,
.cm-standard-input-hover > textarea {
	background-color: transparent;
	border: 1px solid transparent !important;
}

.cm-standard-input-hover > div > input:hover,
.cm-standard-input-hover > div > input:focus,
.cm-standard-input-hover > div > input:active,
.cm-standard-input-hover > textarea:hover,
.cm-standard-input-hover > textarea:focus,
.cm-standard-input-hover > textarea:active {
	background-color: transparent;
	border: 1px solid #ddd !important;
	transition: border 0.3s;
}

/* ------- override margins ------- */

.cm-store-vendor-onboarding-disclaimer {
	margin-top: var(--spacing-double);
	margin-bottom: var(--spacing-normal);
}

.cm-store-vendor-onboarding-disclaimer-card {
	background-color: var(--color-brand-medium-blue);
	padding: 30px 20%;
	color: #fff;
	border-radius: 4px;
}

/* ------------- Training Video ------------ */
.cm-hidden-overlay {
	opacity: 0;
	transition: all 0.3s;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--color-brand-blue);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #fff;
	cursor: pointer;
}

.cm-hidden-overlay:hover {
	opacity: 0.9;
	transition: all 0.3s;
}

/* =============  Order Details =============== */
.cm-order-field-label {
	font-weight: 500;
}

.cm-order-totals-row {
	display: grid;
	grid-template-columns: 125px auto 61px;
	grid-gap: 10px;
	justify-items: end;
	align-items: center;
	margin-bottom: var(--spacing-half);
	margin-left: 20px;
}

.cm-order-field-label-left {
	justify-self: start;
}

.cm-order-totals-actions {
	display: grid;
	justify-items: start;
	grid-template-columns: 30px 30px;
}

.cm-order-details-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	grid-gap: 20px;
	justify-content: center;
	align-content: center;
	align-items: flex-start;
}

.cm-order-subtotal-attached {
	display: block;
}

.cm-order-item-row {
	margin-top: -1px;
	display: grid;
	grid-template-columns: 125px auto 675px;
	grid-gap: 20px;
	justify-items: start;
	align-items: center;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
	padding: var(--spacing-normal) 20px;
}

.cm-order-item-row-image {
	display: flex;
	text-align: center;
	align-content: center;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.cm-order-item-row-image i {
	padding: 5px;
	margin-top: -2px;
}

.cm-buy-again-image-container {
	width: 100%;
	height: 75px;
	position: relative;
}

.cm-buy-again-image-container-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0);
}

.cm-buy-again-image-container:hover .cm-buy-again-image-container-overlay {
	background: rgba(255, 255, 255, 0.8);
}

.cm-buy-again-button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

.cm-buy-again-image-container:hover .cm-buy-again-button {
	opacity: 1;
	cursor: pointer;
}

.cm-buy-again-button-content {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.cm-order-item-row-image div img {
	width: 75px;
}

.cm-order-item-data {
	text-align: center;
	display: grid;
	grid-gap: 10px;
	grid-template-rows: auto;
	grid-auto-flow: column;
	grid-template-columns: 110px 110px 80px 40px 80px repeat(
			auto-fill,
			minmax(40px, 100%)
		);
	justify-items: center;
	width: 100%;
	align-content: center;
	justify-content: center;
	margin: 0;
}

.cm-order-item-data-updated {
	text-align: center;
	display: grid;
	grid-gap: 10px;
	grid-template-rows: auto;
	grid-auto-flow: column;
	grid-template-columns: 80px 40px 80px repeat(auto-fill, minmax(40px, 100%));
	justify-items: center;
	width: 100%;
	align-content: center;
	justify-content: center;
	margin: 0;
}

.cm-order-item-data-edit {
	grid-template-columns: 110px 110px 80px 40px 80px repeat(
			auto-fill,
			minmax(40px, 100%)
		);
}

.cm-order-item-data-select {
	justify-self: stretch;
	margin: 0;
	min-width: 135px;
}

.cm-order-details-title {
	color: #333;
}

.cm-order-item-expanded {
	float: right;
}

.cm-order-item-data-expanded {
	text-align: left;
	align-content: flex-end;
	grid-template-columns: auto;
	padding-right: 20px;
}

.cm-order-item-expanded-view {
	margin: 5px 10px;
}

@media (min-width: 1600px) {
	/* Big screens*/
	/* set the top equal +1 to the height of the nav bar, which at the time of this writing was 77px */
	.cm-order-subtotal-fixed {
		position: fixed;
		top: 78px;
		width: 350px;
	}
}

@media (max-width: 1600px) {
	.cm-order-subtotal-fixed {
		display: grid;
	}
}

@media all and (max-width: 1199px) {
	.cm-order-subtotal-attached {
		display: grid;
	}

	.cm-order-item-row {
		grid-template-columns: auto 655px;
	}

	.cm-order-item-row-image {
		display: none;
	}
}

@media all and (max-width: 991px) {
}

@media all and (max-width: 768px) {
	.cm-order-item-row {
		display: block;
		padding: var(--spacing-double) var(--spacing-normal);
	}

	.cm-order-item-row-image {
		display: block;
		text-align: center;
		margin-bottom: var(--spacing-double);
	}

	.cm-order-item-row-image a img {
		margin: 0 auto;
		display: block;
		width: 30%;
	}

	.cm-order-item-row-details {
		font-size: 1.6em;
		display: block;
		text-align: center;
	}

	.cm-order-item-data {
		margin: var(--spacing-double) 0 var(--spacing-half) 0;
	}

	.cm-order-details-title {
		color: #666;
		font-weight: bolder;
	}
}

@media all and (max-width: 575px) {
}

/* ------------- OrderItems ------------ */
/* ------------- Receive and Reconcile ------------ */
.order-item-reconcile-row {
	width: 100%;
	display: flex;
	flex-direction: row;
	border-bottom: 1px solid #eee;
	padding: 10px 20px 10px 40px;
}

.receive-reconcile-row-image {
	width: 50px;
}

/* ------------- Order Rules ------------ */
.cm-order-rules-modal-master {
	overflow-y: scroll;
	overflow-x: hidden;
	margin-right: -20px;
}

.cm-order-rules-modal-detail {
	display: flex;
	height: 100%;
	flex-direction: column;
	background-color: var(--color-light-grey);
}

.cm-order-rules-modal-content-top {
	height: 50px;
	width: 100%;
}

.cm-order-rules-modal-content {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.cm-order-rules-summary-card {
	max-width: 400px;
}

@media all and (max-width: 575px) {
}

.cm-product-comment-container {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border: 1px solid #eee;
	border-radius: 10px;
	margin-top: 12px;
	margin-left: 4px;
	padding: 4px 15px 15px 15px;
}

.cm-product-comment-user-name {
	font-weight: 700;
	font-size: 12px;
	width: max-content;
}

.cm-product-comment-action-description {
	font-weight: 400;
	font-size: 12px;
}

.cm-product-comment-input-wrapper {
	padding: 4px;
	width: 100%;
}

.cm-product-comment-form-wrapper {
	width: 85%;
	margin-right: 15%;
}

/*--------------------BILLS----------*/

.cm-bill-item-form-row {
	text-align: left;
	display: grid;
	grid-gap: 10px;
	grid-template-rows: auto;
	grid-template-columns: 0.1fr 1.1fr 2fr 0.6fr 0.8fr 0.8fr;
	justify-items: left;
	width: 100%;
	align-content: start;
	justify-content: left;
	margin: 0;
}

.cm-bill-item-form-row-accounting {
	grid-template-columns: 0.1fr 0.8fr 1.4fr 0.8fr 0.8fr 0.8fr 0.6fr 0.8fr 0.8fr;
	justify-content: left;
	justify-items: left;
	text-align: left;
}

.cm-bill-item-form-row p {
	font-size: 0.9em;
	padding: 2px;
	margin: 0;
	align-self: end;
}

.cm-bill-item-form-row span {
	font-size: 0.85em;
	color: #999999;
	align-self: center;
}

.cm-bill-draft-row:hover {
	background-color: #f9f9f9;
	transition: background-color 0.4s;
}

.cm-bill-details-row {
	display: grid;
	grid-gap: 10px;
	grid-template-rows: auto;
	grid-template-columns: 3fr 1fr 325px;
	grid-auto-flow: row;
}

.cm-bill-totals-table {
	grid-column: 3;
	grid-row: 1 / 3;
}

.cm-bill-item-description {
	text-align: left !important;
}

.cm-bill-item-delete {
	position: absolute;
	padding: 0 !important;
	margin: 0 !important;
	color: #f0f !important;
	left: -15px;
	top: -12px;
}

.cm-bill-item-delete span i {
	font-size: 12px !important;
}

.cm-upsert-bill-modal-layout-master {
	width: 300px;
	background-color: #f9f9f9;
	right: 0;
	border: none;
}

.cm-upsert-bill-modal-layout-detail {
	width: calc(100% - 300px);
}

.cm-upsert-bill-modal-layout-detail::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	background: transparent;
}

.cm-upsert-bill-modal-items {
	position: fixed;
	bottom: 50px;
	left: 0;
	right: 0;
	height: 0;
	transition: height 0.5s;
	background-color: #fff;
	overflow: hidden;
	z-index: 1;
}

.cm-upsert-bill-modal-items-show {
	position: fixed;
	bottom: 50px;
	left: 0;
	right: 0;
	height: 40%;
	background-color: #fff;
	z-index: 1;
	overflow-y: scroll;
	border-top: 1px solid #f0f0f0;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	transition: height 0.3s;
}

@media (max-width: 990px) {
	.cm-upsert-bill-modal-layout-master {
		width: 70%;
		min-width: 450px;
	}

	.cm-upsert-bill-modal-layout-detail {
		width: 100%;
		max-width: 100%;
	}
}

.cm-bill-details-container {
	margin-left: auto;
	margin-right: auto;
}

/*--------------------PDF VIEWER----------*/

.cm-file-preview {
	width: 100%;
	height: 100%;
	border-radius: 6px;
	overflow: hidden;
	background-color: #fff;
}

.cm-box-shadow {
	box-shadow: 0 12px 30px 0 rgba(16, 36, 94, 0.2);
}

.cm-box-shadow-sm {
	box-shadow: 0 7px 10px 0 rgba(16, 36, 94, 0.2);
}

.cm-multiple-attachments-controls-container {
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
	z-index: 1;
}

.cm-multiple-attachments-controls {
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 12px 30px 0 rgba(16, 36, 94, 0.2);
}

.cm-multiple-attachments-control-button-disabled {
	background-color: #fff !important;
}

.disabled-checkout-next-btn {
	color: #403d39;
	cursor: auto;
}

.disabled-checkout-next-btn:hover {
	color: #403d39;
}

.preview-file-download-icon:hover {
	cursor: pointer;
	color: #2ba9cd;
}

/*----------Documentation Table----------*/

.cm-csv-documentation-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
}

/* ------------- Transcriptions ------------ */

.cm-transcriptions-header-card {
	display: grid;
	grid-template-rows: 75px 75px;
}

.cm-transcriptions-header-child {
	justify-self: center;
	align-self: center;
}

.cm-mt-neg-10 {
	margin-top: -10px;
}

.cm-scroll-container {
	flex: 1;
	width: 100px;
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
}

.cm-scroll-container::-webkit-scrollbar {
	display: none;
}

/* --------- Skeletons ------------ */
.skeleton-pulse {
	border-radius: 3px;
	background-color: #f0f0f0;
	animation: skeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite;
}

@keyframes skeleton-keyframes-pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.4;
	}
	100% {
		opacity: 1;
	}
}

/* --------- Analytics ------------ */

.cm-spend-by-entity-tooltip {
	width: 300px;
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: center;
	padding: 10px;
}

.cm-spend-by-entity-tooltip .cm-spend-by-entity-tooltip-left {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.cm-spend-by-entity-tooltip .cm-spend-by-entity-tooltip-right {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.cm-loading-view-fill-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

/* -------- Tags ------------ */
.cm-tag-input {
}

.cm-tag-input input {
	font-family: 'Outfit', sans-serif;
	width: 150px;
}

.cm-tag-input input::placeholder {
	color: #999;
}

.react-tagsinput-tag a::before {
	color: #fff;
}

li > button.cm-search-result-item.highlight {
	background: #e1f5fe;
}

.cm-search-result-item.highlight:hover {
	background: #e1f5fe;
}

/* =========== codejar =========== */

.codejar-wrap {
	height: 100%;
}

.codejar-linenumbers {
	padding-left: 10px !important;
	color: #555 !important;
	background-color: #020202 !important;
}

.codejar-content {
	height: 100% !important;
}

/* ========== iconbutton =========== */
.cm-icon-button-xs span i {
	font-size: 12px !important;
}

/* ========== React DatePicker =========== */
.react-datepicker-wrapper > div > input {
	margin-bottom: 10px;
	padding: 8px;
	border: 1px solid #ddd;
	border-radius: 5px;
}

/* ------------- Find Products  ------------ */
.mdl-layout {
	display: flex;
	flex: 1;
	height: 100%;
}

.mdl-layout-left {
	flex-direction: row;
}

.mdl-layout-right {
	flex-direction: row-reverse;
}

@media (max-width: 768px) {
	.mdl-layout {
		display: flex;
		flex: 1;
		height: 100%;
	}

	.mdl-layout-left {
		flex-direction: column;
	}

	.mdl-layout-right {
		flex-direction: column;
	}
}

/* ------------ DEV Tools ------------ */
.cm-devtools {
	position: sticky;
	width: 100%;
	margin-top: 250%;
	bottom: 0;
	height: 40px;
	padding: 5px;
	color: #fff;
	font-weight: bold;
	text-align: center;
	font-size: 0.8em;
	cursor: pointer;
}

.cm-devtools-dev {
	background-color: #367c39;
}

.cm-devtools-dev:hover {
	background-color: #225024;
}

.cm-devtools-stage {
	background-color: #dcb04e;
}

.cm-devtools-stage:hover {
	background-color: #a6853b;
}

.cm-devtools-proto {
	background-color: #542968;
}

.cm-devtools-proto:hover {
	background-color: #2c1536;
}
